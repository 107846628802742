li#popover-preferred-theme {
    position: relative;

    button {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        color: var(--single-card-text-color);
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        font-family: system-ui;
        background: none;
        padding: 10px 20px;
        border: none;
        box-shadow: none;
        cursor: pointer;
        width: 100%;
        &:hover {
            background-color: var(--profile-card-action-background-hover-color);
        }

        span {
            font-size: 18px;
        }
    }
}
.preferred-theme {
    padding: 5px 0;
    width: 240px;
    background-color: var(--popover-background-color);
    border-radius: 8px;
    z-index: 4;
    box-shadow: var(--popover-box-shadow);
    border: 1px solid var(--popover-border-color);

    .theme-options li {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-size: 20px;
        }
    }

    h2 {
        margin-bottom: 20px;
        margin-top: 0px;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }
}

.preferred-theme {
    position: absolute;
    right: 100%;
    top: 0;
    h2 {
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .theme-options {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            padding: 10px 15px;
            font-size: 14px;
            cursor: pointer;
            transition:
                background-color 0.3s ease,
                color 0.3s ease;
            color: var(--primary-text-color);
            font-weight: 500;

            &:hover {
                background-color: var(--select-item-hover-background-color);
            }

            &.selected {
                background-color: var(--select-option-hover-background);
                border-left: 2px solid var(--brand-color);
                color: var(--brand-color);
                -webkit-user-select: none;
                user-select: none;
                margin-left: -1px;
            }
        }
    }

    .loading-indicator {
        margin-top: 10px;
        font-size: 14px;
        color: #888;
        font-style: italic;
    }
}
