.search-select {
    position: relative;
    width: 100%;
    cursor: pointer;

    &.focused {
        .search-select-trigger {
            outline: 2px solid var(--brand-color);
        }
    }

    .search-select-trigger {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        color: var(--primary-text-color);
        font-family: system-ui;
        justify-content: space-between;
        position: relative;
        // background-color: var(--filter-placeholder-background-color);
        background-color: var(--popover-background-color);
        outline: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        cursor: pointer;

        input {
            margin-bottom: 0;
            background: transparent;
            border: none;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            width: 100%;
            cursor: pointer;
            outline: none;
            font-weight: 500;
            font-size: 14px;

            &::placeholder {
                color: var(--single-card-text-color);
                font-weight: 500;
            }
        }

        span.material-symbols-outlined {
            background: none;
            font-size: 20px;
            margin-right: 4px;
        }
    }

    .search-select-options {
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        background-color: var(--popover-background-color);
        border-radius: var(--element-border-radius);
        width: 100%;
        z-index: 1000;
        padding: 5px 0;
        max-height: 500px;
        overflow: auto;
        margin-top: 0px;
        max-height: 300px;
        border: 1px solid var(--popover-border-color);

        .search-select-option {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            transition: background 0.2s;
            padding: 8px 10px;
            color: var(--single-card-text-color);
            font-size: 14px;
            line-height: 1.4;
            font-weight: 500;
            border-left: 2px solid transparent;

            &:hover {
                color: var(--selected--option-text-color);
                background: var(--select-option-hover-background);
                border-left: 2px solid var(--brand-color);
            }

            &.selected {
                color: var(--selected-option-text-color);
                border-left: 2px solid var(--brand-color);
                background: var(--select-option-hover-background);
            }

            .current-option {
                font-size: 12px;
            }
        }
    }
}

li.search-select-group {
    margin-bottom: 10px;

    label.search-select-group-label {
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: normal;
        color: var(--single-card-text-color);
        margin-bottom: 5px;
        display: block;
    }
    ul.search-select-option-list {
        padding: 0;
    }
}
