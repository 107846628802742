.card-table {
    width: calc(100% - 100px);
    border-collapse: collapse;
    font-family: system-ui;
    color: #d9d9d9;
    max-width: 1200px;
    margin: auto;

    .table-header {
        padding: 10px 0;
        font-weight: bold;

        display: grid;
        grid-template-columns: minmax(0, 500px) minmax(0, 220px) minmax(0, 200px) minmax(0, 160px) minmax(0, 200px);
        grid-template-rows: auto;
        column-gap: 8px;
        border-bottom: 1px solid var(--ds-background-neutral, #091e420f);
        text-decoration: none;

        .header-item {
            flex: 1;
            text-align: left;
            color: var(--primary-text-color);
        }
    }

    .table-body {
        .table-row {
            // display: flex;
            border-top: 1px solid #3a3a3a;

            a {
                padding: 12px 4px;
                color: #d9d9d9;
                text-decoration: none;
                font-weight: 400;
                line-height: 1.6;
                font-size: 15px;

                &:hover {
                    background-color: #2a2a2a;
                    color: var(--brand-color);
                }
            }

            display: grid;
            grid-template-columns: minmax(0, 500px) minmax(0, 220px) minmax(0, 200px) minmax(0, 160px) minmax(0, 200px);
            grid-template-rows: auto;
            column-gap: 8px;
            border-bottom: 1px solid var(--ds-background-neutral, #091e420f);
            text-decoration: none;

            .table-cell {
                flex: 1;
                display: flex;
                align-items: center;
                font-size: 15px;
                color: var(--primary-text-color);
                word-break: break-word;

                &.board-info {
                    display: flex;
                    align-items: center;

                    .board-image {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        border-radius: 3px;
                    }
                }
            }

            .labels {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;

                .label {
                    width: 20px;
                    height: 5px;
                    border-radius: 3px;
                    &.green {
                        background-color: #4caf50;
                    }
                    &.purple {
                        background-color: #9c27b0;
                    }
                    &.blue {
                        background-color: #2196f3;
                    }
                    &.orange {
                        background-color: #ff9800;
                    }
                    &.red {
                        background-color: #f44336;
                    }
                }
            }

            .due-date {
                display: flex;
                align-items: center;

                &.completed {
                    background-color: #004085;
                    padding: 2px 5px;
                    border-radius: 3px;
                    color: var(--white-text-color-alternative);
                    font-size: 12px;
                }
            }
        }
    }
}

.profile-page.card-loading {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;

    .top-profile {
        flex-shrink: 0;
    }

    .card-table {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .table-body {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.empty-activity {
    width: 1200px;
    margin: auto;
    max-width: calc(100% - 100px);
    color: #5d5d5d;
    text-align: center;
}
