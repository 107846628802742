.members-search-result {
    .mention-suggestion.selected {
        background-color: #f0f0f0;
    }
    .suggession-name,
    .suggession-handler {
        font-size: 14px;
        line-height: 1.4;
        color: var(--single-card-text-color);
    }
    .suggession-handler {
        font-size: 12px;
        color: #8b9aaa;
        font-weight: 500;
    }

    .mention-suggestion.selected {
        background-color: var(--selected-item-background-color);
    }
    .mention-suggestion {
        transition: 0.2s;
        gap: 5px;
        display: flex;
        align-items: center;
        padding: 12px 10px;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 4px;
        gap: 12px;
        &:last-child {
            margin: 0;
        }
    }
    .mention-suggestion:hover {
        background-color: var(--select-item-hover-background-color);
    }
}

.mention-suggestion {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;

    &.selected {
        .suggession-name {
            font-weight: 600;
        }
    }

    img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .profile-placeholder {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #ccc;
    }

    .suggession-name {
        font-weight: 600;
    }

}
