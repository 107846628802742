.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.lightbox-content {
    max-width: calc(100% - 160px);
}


.lightbox-image {
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: transform 0.2s ease;
    max-height: calc(100vh - 200px);
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;

    &:hover {
        background: rgba(255, 255, 255, 0.4);
    }
}

/* Fullscreen Button */
.fullscreen-btn {
    position: absolute;
    top: 15px;
    left: 15px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;

    &:hover {
        background: rgba(255, 255, 255, 0.4);
    }
}

/* Navigation Buttons */
.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;

    &:hover {
        background: rgba(255, 255, 255, 0.4);
    }
}

.prev-btn {
    left: 20px;
}

.next-btn {
    right: 20px;
}

/* Lightbox Header (for numbering) */
.lightbox-header {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px 12px;
    border-radius: 5px;
}

.lightbox-info {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px;
    padding: 6px 12px;
    border-radius: 5px;

    .file-name {
        font-size: 16px;
        font-weight: bold;
    }

    .file-details {
        font-size: 14px;
        opacity: 0.8;
    }
}


button.next-btn svg, .prev-btn svg {
    width: 40px;
    height: 40px;
}