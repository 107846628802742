.list-actions {
    padding: 12px 15px;
    width: 280px;

    .back-button {
        background: none;
        border: none;
        color: var(--brand-color);
        font-size: 16px;
        cursor: pointer;
        display: flex;
        position: absolute;
        top: 10px;
        left: 6px;

        span {
            font-size: 20px;
        }
    }

    h2 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .copy-list-form {
        textarea {
            width: calc(100% - 30px);
            min-height: 36px;
            margin: 0;
            padding: 8px 12px;
            overflow: hidden;
            overflow-y: auto;
            border: none;
            border-radius: 8px;
            background-color: var(--ds-surface-raised, var(--popover-background-color));
            box-shadow: var(
                --ds-shadow-raised,
                0px 1px 1px rgba(9, 30, 66, 0.2509803922),
                0px 0px 1px rgba(9, 30, 66, 0.3098039216)
            );
            resize: none;
            overflow-wrap: break-word;
            font-family: system-ui;
            border: 2px solid transparent;
            overflow: hidden;
            resize: none;
            line-height: 1.4;
            font-weight: 500;
            margin-bottom: 10px;
            color: var(--primary-text-color);

            &:focus-visible {
                outline: 2px solid var(--brand-color);
            }
            &::placeholder {
                color: var(--primary-text-color);
                font-weight: 500;
                color: var(--editor-placeholder-color);
            }
            outline: 1px solid var(--outline-color);
        }

        button {
            background-color: var(--brand-color);
            color: var(--white);
            border: none;
            padding: 5px 15px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            transition: background-color 0.3s;
            height: 32px;
        }
    }

    p {
        font-size: 14px;
        font-family: system-ui;
        font-weight: 500;
        line-height: 1.4rem;
        color: #b1b1c3;
        font-weight: 400;
    }

    ul {
        font-size: 14px;
        font-family: system-ui;
        padding: 0;
        margin: 0 -15px;
    }

    li {
        line-height: 1.6;
        list-style: none;
        padding: 6px 20px;

        &:hover {
            background-color: var(--board-menu-item-background-color);
            cursor: pointer;
        }
    }

    a {
        color: var(--primary-color);
        text-decoration: none;
    }
}
