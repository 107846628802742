.zoobbe-info {
    padding: 15px;
    width: 280px;

    h2 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    p {
        font-size: 14px;
        font-family: system-ui;
        font-weight: 500;
        line-height: 1.4rem;
        color: #b1b1c3;
        font-weight: 400;
    }

    ul {
        padding-left: 15px;
        font-size: 14px;
        font-family: system-ui;
    }

    li {
        margin-bottom: 5px;
        line-height: 1.6;
    }

    a {
        color: var(--primary-color);
        text-decoration: none;
    }
}
