.create-workspace-form,
.create-board-form {
    background-color: var(--create-form-background-color);
    color: var(--white-text-color-alternative);
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    margin: 0 auto;
    text-align: center;
    .background-selector {
        display: flex;
        gap: 7px;

        img {
            width: 70px;
            height: 45px;
            object-fit: cover;
            border-radius: 5px;
        }
    }
    h1 {
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 1rem;
        color: var(--primary-text-color);
    }
    .group {
        width: 100%;
        text-align: left;
        input#board-title,
        input#workspace-name {
            margin-bottom: 10px;
        }
        input,
        select,
        textarea {
            margin: 0;
            background-color: var(--input-background-color);
        }
        margin-bottom: 20px;
        p {
            margin: 0;
        }

        .board-preview {
            height: 160px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            align-content: center;
            position: relative;
            background-position: center;

            img {
                width: calc(100% - 70px);
                height: auto;
                z-index: 1;
                position: relative;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                z-index: 0;
            }
        }
    }

    p {
        margin-bottom: 2rem;
        font-size: 0.9rem;
        font-family: system-ui;
        font-weight: 400;
    }
    p.error-message {
        margin-bottom: 0;
    }

    // .zoobbe-select {
    //     margin-bottom: 20px;
    // }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .group h3 {
            font-size: 12px;
            margin-top: 0;
        }

        label {
            align-self: flex-start;
            margin-bottom: 0.8rem;
            font-family: system-ui;
            font-weight: 600;
            color: #797a7c;
            font-size: 0.8rem;
        }

        input,
        select,
        textarea {
            width: calc(100% - 25px);
            padding: 0 12px;
            margin-bottom: 1.4rem;
            outline: 1px solid var(--scrolbar-thumb-background-color);
            border-radius: var(--element-border-radius);
            background-color: #111;
            color: var(--white-text-color-alternative);
            height: 35px;
            font-family: system-ui;
            border: none;
            &::placeholder {
                color: #666;
            }
            &:focus-visible,
            &:focus {
                outline: 2px solid var(--brand-color);
            }
        }

        select#workspace-type {
            width: 100%;
            cursor: pointer;
        }

        textarea {
            height: 80px;
            padding: 12px;
        }

        button {
            background-color: var(--brand-color);
            color: var(--white);
            border: none;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            width: 100%;
            padding: 0;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #135bb7;
            }
        }
    }
}

.create-board-form,
.create-workspace-form {
    width: 300px;
}

.background-selector {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    .background-option {
        position: relative;
        width: 100%;
        height: 40px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.2s ease;
        background-repeat: no-repeat;

        &:hover {
            outline: 2px solid var(--brand-color);
        }

        &.selected {
            outline: 2px solid var(--brand-color);

            .checkmark {
                display: block;
            }
        }

        .checkmark {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            background: var(--brand-color);
            color: var(--white);
            font-size: 14px;
            line-height: 20px;
            border-radius: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.background-selector.colors {
    margin-bottom: 0;

    .background-option {
        height: 30px;
        border-radius: 4px;
    }
}

.create-workspace-form {
    border-radius: 8px 8px;
    padding: 35px;
    text-align: left;
    h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 1rem;
        margin-top: 0;
        line-height: 1.4em;
        margin-bottom: 20px;
    }
    p.error-message {
        font-size: 14px;
    }
    input,
    textarea {
        font-size: 15px;
        font-weight: 500;
        color: var(--primary-text-color);
    }

    .zoobbe-select .zoobbe-select-trigger {
        color: var(--primary-text-color);
    }
}

.workspace-container {
    display: flex;

    .workspace-right-content {
        width: 320px;
        max-height: 100%;
        background: url(../../../../public/create-workspace-shape.svg);
        background-color: var(--input-background-color);
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;

        img {
            width: calc(100% - 50px);
            height: auto;
            margin-top: -200px;
        }
    }
}
