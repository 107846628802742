// quill editor style
span.ql-formats button:hover {
    background: var(--editor-background-color);
}

.zoobbe-card-no-description {
    height: 50px;
    background: var(--single-card-side-action-bg-color);
    padding: 10px 12px;
    cursor: pointer;
    border-radius: var(--editor-border-radius);
    transition: 0.2s;
    &:hover {
        background-color: var(--single-card-action-button-hover-color);
    }
}

.zoobbe-description,
.ql-editor,
.comment-section {
    font-size: 15px;
    border: none;
    line-height: 1.4rem;
    margin-bottom: 15px;

    /* Style headings */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: system-ui;
        font-weight: bold;
        margin-bottom: 10px;
        color: var(--primary-text-color);
        margin-top: 0;
    }

    h1 {
        font-size: 2.5rem;
        line-height: 1.2;
    }

    h2 {
        font-size: 2rem;
        line-height: 1.3;
    }

    h3 {
        font-size: 1.75rem;
        line-height: 1.4;
    }

    h4 {
        font-size: 1.5rem;
        line-height: 1.5;
    }

    h5 {
        font-size: 1.25rem;
        line-height: 1.6;
    }

    h6 {
        font-size: 1rem;
        line-height: 1.7;
    }

    /* Style paragraphs */
    p {
        margin-bottom: 10px;
        word-break: break-word;
        font-weight: 500;
        font-size: 15px;
        font-family: system-ui;
        color: var(--single-card-text-color);
        margin-top: 0;

        img {
            max-width: 100%;
            height: auto;
            border: 1px solid var(--editor-image-border-color);
            border-radius: 4px;
            cursor: pointer;
        }
    }

    /* Style links */
    a {
        color: var(--brand-color);
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    /* Style lists */
    ul,
    ol {
        margin-bottom: 15px;
        word-break: break-word;
        font-weight: 500;
        font-size: 15px;
        font-family: system-ui;
        color: var(--primary-text-color);
    }

    li {
        margin-bottom: 5px;
        line-height: 1.6rem;
    }

    /* Style blockquote */
    blockquote {
        margin: 0;
        padding: 10px 20px;
        background-color: #f9f9f9;
        border-left: 5px solid #ccc;
    }

    /* Style code blocks */
    pre {
        background-color: var(--single-card-side-action-bg-color);
        padding: 10px;
        border: 1px solid #9aa0a60f;
        border-radius: var(--element-border-radius);
        overflow-x: auto;
        line-height: 1.6rem;
        margin-top: 0;
    }

    code {
        font-family: Consolas, Monaco, "Andale Mono", monospace;
        line-height: 1.6rem;
    }

    /* Style tables */
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 15px;
    }

    th,
    td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
    }

    /* Style forms */
    input,
    textarea {
        padding: 8px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: var(--element-border-radius);
        box-sizing: border-box;
    }

    textarea {
        min-height: 100px;
        resize: vertical;
    }

    /* Style buttons */
    button {
        padding: 0px 20px;
        background-color: var(--brand-color);
        color: var(--white);
        border: none;
        border-radius: var(--element-border-radius);
        cursor: pointer;
        height: 35px;
    }

    button:hover {
        background-color: var(--hover-brand-color);
    }

    .editor-action-buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 12px;

        button {
            font-weight: 500;
            font-size: 15px;
            transition: 0.3s;
            background-color: var(--single-card-side-action-bg-color);
            color: var(--single-card-text-color);

            &:hover {
                background-color: var(--single-card-action-button-hover-color);
            }
        }

        button.cancel-editing-content {
            background: none;
            color: var(--single-card-text-color);

            &:hover {
                background-color: var(--single-card-action-button-hover-color);
            }
        }
    }
}

.mention-popover {
    background-color: var(--popover-background-color);
    border: 1px solid var(--popover-border-color);
    border-radius: 8px;
    box-shadow: var(--popover-box-shadow);
    z-index: 1000;
    padding: 8px;
}

.mention-suggestion {
    padding: 8px 12px;
    cursor: pointer;
}

.mention-suggestion:hover {
    background-color: #f0f0f0;
}

.zoobbe-card-details-header.with-cover .textarea-wrapper {
    flex: 1;
    margin: -6px 0;
}
