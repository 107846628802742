.zoobbe-header__icons {
    .notifications-icon {
        cursor: pointer;
        user-select: none;
    }

    .notifications-container {
        position: relative;

        .notifications-icon {
            transition: 0.3s;
            width: 32px;
            height: 32px;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--single-card-side-action-bg-color);

            &:hover {
                background: var(--single-card-action-button-hover-color);
            }

            svg {
                width: 20px;
                height: 20px;
                fill: var(--primary-text-color);
            }

            .notification-counter {
                position: absolute;
                font-size: 11px;
                left: 20px;
                top: -5px;
                color: var(--white);
                font-weight: bold;
                min-width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                background: red;
                justify-content: center;
                border-radius: 50px;
            }

            // &:hover {
            // }
        }

        .notifications-icon.active {
            background: var(--notifications-icon-hover-color);
            svg {
                fill: var(--brand-color);
            }
        }
    }

    .profile-container {
        position: relative;
        .profile-image {
            display: flex;
            align-items: center;

            .image-placeholder.active {
                outline: 5px solid var(--profile-active-border-color);
            }
        }
    }
    .notifications-panel {
        background-color: var(--popover-background-color);
        padding: 16px;
        width: 320px;
        border-radius: 8px;
        color: var(--white-text-color-alternative);
        position: absolute;
        right: -50px;
        top: 40px;
        z-index: 3;
        -webkit-user-select: none;
        user-select: none;
        box-shadow: var(--popover-box-shadow);
        z-index: 4;
        outline: 1px solid var(--popover-border-color);
        .spin-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 150px;
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            flex-direction: column;
            gap: 30px;

            button {
                background: none;
                border: none;
                color: var(--white-text-color-alternative);
                text-align: right;
                cursor: pointer;
            }

            h2 {
                font-size: 14px;
                margin: 0;
            }

            .actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                // span.show-unread-checkbox {
                //     display: flex;
                //     align-items: center;
                //     gap: 2px;
                // }
                span.show-unread-checkbox {
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    input[type="checkbox"] {
                        margin-left: 8px;
                    }
                }

                button {
                    background: none;
                    border: none;
                    color: var(--white-text-color-alternative);
                    font-size: 14px;
                    margin-left: 16px;
                    cursor: pointer;
                }

                .checkbox {
                    border: 2px solid var(--checkbox-border-color);
                }
            }
        }

        .notification-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .notification-item {
                display: flex;
                align-items: flex-start;
                padding: 12px;
                border-radius: 8px;
                background-color: var(--popover-background-color);
                position: relative;
                margin-bottom: 8px;
                gap: 10px;
                border: 1px solid transparent;
                cursor: pointer;

                &:hover {
                    // background-color: #282c34;
                    border: 1px solid var(--notification-item-hover-border-color);
                }

                &.unread {
                    font-weight: 500;
                }

                a.target-link {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                }

                .user-avatar {
                    width: 40px;
                    height: 40px;

                    img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                }

                .notification-content {
                    flex-grow: 1;

                    .initiator {
                        margin-bottom: 5px;
                        display: inline-block;
                        color: var(--brand-color);
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .content {
                        margin: 0 0 4px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.6;
                        margin-right: 5px;
                        color: var(--primary-text-color);

                        a {
                            color: var(--brand-color);
                        }
                        p {
                            display: inline;
                            word-break: break-word;
                            color: var(--primary-text-color);
                        }
                    }
                    .target-type {
                        color: var(--notification-item-unread-color);
                        text-decoration: none;
                        margin-right: 5px;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .comment-box {
                        background-color: #ff4444;
                        color: var(--white-text-color-alternative);
                        border-radius: var(--element-border-radius);
                        padding: 4px 8px;
                        margin-top: 4px;

                        .comment-user {
                            font-weight: bold;
                        }
                    }

                    .notification-date {
                        font-size: 12px;
                        color: #888888;
                        font-weight: 300;
                        font-family: system-ui;
                    }
                }

                .unread-indicator {
                    width: 10px;
                    height: 10px;
                    background-color: var(--brand-color);
                    border-radius: 50%;
                    position: absolute;
                    top: 12px;
                    right: 12px;
                }
            }

            li.prev-notification-link {
                text-align: center;
                margin-top: 8px;

                a {
                    padding: 10px 5px;
                    text-decoration: none;
                    color: var(--white-text-color-alternative);
                    border-radius: 5px;
                    background: var(--prev-notification-background-color);
                    display: block;
                    font-weight: 500;
                    font-size: 15px;
                    transition: 0.2s;
                    &:hover {
                        background-color: var(--prev-notification-background-hover-color);
                    }
                }
            }
        }
    }
}

.notification-empty-content {
    text-align: center;
    color: #5d5d5d;
    height: 240px;
    align-items: center;
    align-content: center;
    width: 200px;
    justify-content: center;
    margin: auto;

    span.material-symbols-outlined {
        font-size: 72px;
        font-weight: 400;
    }

    h6 {
        padding: 0;
        margin-top: 10px;
        font-size: 15px;
        font-family: system-ui;
        margin-bottom: 0;
    }
    p {
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 8px;
        line-height: 1.4;
        font-weight: 500;
    }
}
