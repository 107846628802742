.boards-page-board-section-list-item {
    // background-color: #171b1e;
    // border: 2px solid #1c2023;
    // border-radius: 10px;
    // min-height: 80px;
    // padding: 10px;
    // position: relative;
    // object-fit: cover;
    // background-size: cover;
    // background-repeat: no-repeat;
    // overflow: hidden;

    // background-color: #171b1e;
    // border: 2px solid #1c2023;
    border-radius: 8px;
    min-height: 100px;
    padding: 10px;
    position: relative;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .board-gradient {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; /* Ensure it's above the background but below content */
        transition: 0.2s;
    }
    &:hover {
        .board-gradient {
            opacity: 0.5;
        }
    }
    .board-tile-link {
        position: relative; /* Ensures child elements appear above the overlay */
        z-index: 2;
    }
    .board-title {
        z-index: 1;
    }

    .board-tile-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .board-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;

        .board-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: var(--single-card-text-color);
        }

        .top-stats {
            display: flex;
            justify-content: space-between;

            .zoobbe-star {
                position: relative;
                z-index: 3;
                margin-top: -50px;
                transition: 0.2s;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                cursor: pointer;
                svg,
                span {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    color: white;
                }
            }
        }

        .board-details {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-bottom: -50px;
            transition: 0.2s;

            .board-stats span.material-symbols-outlined {
                font-size: 16px;
                margin-right: 5px;
                color: var(--white);
            }

            .board-members {
                display: flex;
                align-items: center;
                position: relative;
                z-index: 3;

                // &:hover .avatar {
                //     margin-right: -2px;
                // }

                .avatar {
                    transition: 0.2s;
                    // .image-placeholder {
                    //     border: 2px solid #171b1e29;
                    // }

                    .image-placeholder {
                        color: var(--white) !important;
                        font-weight: 500 !important;
                        font-size: 14px !important;
                        backdrop-filter: blur(2px);
                    }
                }
                .members-info {
                    gap: 3;
                }

                .member-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: -10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .member-count {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    z-index: 2;
                    cursor: pointer;
                    color: var(--white);
                    background: #171b2052;
                    margin-left: 3px;
                    transition: 0.2s;
                    user-select: none;
                    &:hover {
                        background: #40485194;
                    }
                }
            }

            .board-stats {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                z-index: 1;

                .stats-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    fill: #666;
                }
            }
        }
    }
}

.boards-page-board-section-list-item:hover .board-details {
    margin: 0;
}
.boards-page-board-section-list-item:hover .top-stats .zoobbe-star {
    margin: 0;
}
