.create-feedback-form {
    background-color: var(--popover-background-color);
    color: var(--white-text-color-alternative);
    padding: 20px 0 0;
    border-radius: 8px;
    width: 340px;
    margin: 0 auto;
    text-align: center;
    padding-top: 15px;

    .background-selector {
        display: flex;
        gap: 7px;

        img {
            width: 70px;
            height: 45px;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    h1 {
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 1rem;
        color: var(--primary-text-color);
    }

    .group-container {
        max-height: 550px;
        overflow: auto;
        width: calc(100% - 40px);
        margin: auto;
        padding: 0 18px;
    }

    .group {
        width: 100%;
        text-align: left;
        input#feedback-title,
        input#workspace-name {
            margin-bottom: 10px;
            font-weight: 500;
            width: calc(100% - 24px);
        }

        input,
        select,
        textarea {
            margin: 0;
        }

        margin-bottom: 20px;

        p {
            margin: 0;
        }

        p.feedback-page-link {
            font-size: 14px;
            line-height: 1.5;
            padding: 0 20px;
            color: #fff;
            font-weight: 400;
        }

        .feedback-preview {
            height: 160px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            align-content: center;
            position: relative;
            background-position: center;

            img {
                width: calc(100% - 70px);
                height: auto;
                z-index: 3;
                position: relative;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                z-index: 0;
            }
        }

        .screenshot-preview {
            position: relative;
            span {
                position: absolute;
                top: 10px;
                right: 10px;
                background: #323232;
                padding: 4px;
                font-size: 16px;
                border-radius: 50px;
                color: #fff;
                display: none;
                cursor: pointer;
            }

            &:hover {
                span {
                    display: block;
                }
            }
        }

        /* Group container for the radio buttons */
        .group {
            margin-bottom: 15px;
        }

        /* Radio button container */
        .radio-group {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        /* Hide default radio button */
        .radio-group input[type="radio"] {
            display: none;
        }

        /* Custom radio button style */
        .radio-group label {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 10px 15px;
            border: 2px solid var(--outline-color);
            border-radius: 8px;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
        }

        /* Change border and background when selected */
        label.checked {
            border-color: var(--brand-color);
            color: var(--brand-color);
        }

        /* Hover effect */
        .radio-group label:hover {
            border-color: var(--brand-color);
            color: var(--brand-color);
        }

        /* Disabled state */
        .radio-group input[type="radio"]:disabled + label {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    p {
        margin-bottom: 2rem;
        font-size: 0.9rem;
        font-family: system-ui;
        font-weight: 400;
    }

    p.error-message {
        margin-bottom: 0;
        color: var(--error-message-color); // Using new variable
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .group h3 {
            font-size: 12px;
            margin-top: 0;
        }

        label {
            align-self: flex-start;
            font-family: system-ui;
            font-weight: 600;
            color: #797a7c;
            font-size: 0.8rem;
        }

        input,
        select,
        textarea {
            width: calc(100% - 24px);
            padding: 0 12px;
            margin-bottom: 1.4rem;
            border: 1px solid var(--scrolbar-thumb-background-color);
            border-radius: var(--element-border-radius);
            background-color: var(--input-background-color);
            color: var(--white-text-color-alternative);
            height: 35px;
            font-family: system-ui;
            font-size: 14px !important;
            line-height: 1.4;
            font-weight: 500;

            &::placeholder {
                color: #666;
                font-size: 15px !important;
            }

            &:focus-visible,
            &:focus {
                outline: 2px solid var(--brand-color);
            }
        }

        .search-select-trigger {
            outline-color: var(--scrolbar-thumb-background-color);

            input,
            select,
            textarea {
                &:focus-visible,
                &:focus {
                    outline: none;
                }
            }
        }

        select#workspace-type {
            width: 100%;
            cursor: pointer;
        }

        textarea {
            height: 100px;
            padding: 10px 12px;
            width: calc(100% - 24px);
            font-weight: 500;
        }

        button {
            background-color: var(--brand-color);
            color: var(--white);
            border: none;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            width: 100%;
            padding: 0;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: var(--primary-color); // Using new variable
            }
        }
    }
}

.group.feedback-submit-buttons {
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 10px;
    width: calc(100% - 20px);

    .cancel-button {
        background-color: var(--scrolbar-thumb-background-color);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 15px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #555;
        }
    }
}
