// Set the base styles for light theme
:root[data-theme="light"] {
    --text-color: #333333;
}

// Set the base styles for dark theme
:root[data-theme="dark"] {
    --text-color: #ffffff;
}

// General Page Styles
.page-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    max-width: 900px;
    margin: auto;

    h1,
    h3 {
        color: var(--text-color);
        font-weight: 600;
    }

    p {
        color: var(--text-color);
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    h3 {
        margin-top: 2rem;
        font-size: 1.25rem;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
        color: var(--text-color);
    }

    ul li {
        margin-bottom: 0.5rem;
    }

    // Section styles
    section {
        margin-bottom: 2rem;
    }

    // Add some margin for the last paragraph in the page
    .page-container p:last-child {
        margin-bottom: 0;
    }

    // Link Styles for footer
    .footer-link {
        text-decoration: none;
        color: #007bff;
        font-weight: bold;
        margin-right: 10px;

        &:hover {
            color: #0056b3;
        }
    }

    // Footer Styles
    .footer {
        padding: 20px 0;
        background-color: #f8f9fa;
        border-top: 1px solid #ddd;
        text-align: center;

        .footer-link {
            margin: 0 10px;
        }
    }

    // Button to toggle theme
    .theme-toggle-button {
        background-color: transparent;
        border: 1px solid var(--text-color);
        color: var(--text-color);
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
        transition:
            background-color 0.3s,
            color 0.3s;

        &:hover {
            background-color: var(--text-color);
            color: var(--background-color);
        }

        &.dark-mode {
            border-color: var(--text-color);
            color: var(--text-color);

            &:hover {
                background-color: var(--text-color);
                color: var(--background-color);
            }
        }
    }
}
