.tooltip {
    position: absolute;
    background: var(--scrolbar-thumb-background-color);
    color: var(--primary-text-color);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 9999;
    white-space: nowrap;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    outline: 1px solid var(--tooltip-border-color);
}

.tooltip-top {
    transform: translate(-50%, -100%);
    margin-bottom: 5px;
    transition: .3s;

}

.tooltip-bottom {
    transform: translate(-50%, 0);
    margin-top: 5px;
    transition: .3s;

}

.tooltip-left {
    transform: translate(-100%, -50%);
    margin-right: 5px;
    transition: .3s;


}

.tooltip-right {
    transform: translate(0, -50%);
    margin-left: 5px;
    transition: .3s;


}