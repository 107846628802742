$profile-picture-size: 50px;
$secondary-text-color: var(--primary-text-color);

.top-profile {
    .account-section {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px 0;
        gap: 10px;
        border-bottom: 1px solid var(--scrolbar-thumb-background-color);
        width: calc(100% - 300px);
        margin: 0 auto;
        margin-top: 40px;

        .profile-picture {
            width: $profile-picture-size;
            height: $profile-picture-size;
            border-radius: 50%;
            background-color: #fff;
        }

        .account-info {
            flex-grow: 1;

            .username {
                font-weight: bold;
                margin: 0;
                color: var(--primary-text-color);
                margin-bottom: 2px;
            }

            .email {
                margin: 0;
                color: var(--primary-text-color);
                font-weight: 400;
            }
        }
    }

    nav.navbar {
        margin-bottom: 20px;
        .active {
            font-weight: 500;
            color: var(--notification-item-unread-color); /* Example color for the active link */
            position: relative;

            &::before {
                position: absolute;
                height: 2px;
                width: 100%;
                background: var(--notification-item-unread-color);
                content: "";
                top: -1.5px;
            }
        }

        ul {
            max-width: calc(100% - 300px);
            margin: 0 auto;
            padding: 0;
            display: flex;
            gap: 20px;
            li {
                list-style: none;
                a {
                    text-decoration: none;
                    border: none;
                    box-sizing: border-box;
                    color: var(--ds-text-subtle, #44546f);
                    display: inline-block;
                    font-weight: 500;
                    padding: 15px 0;
                    text-decoration: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .top-profile .account-section {
        max-width: calc(100% - 100px);
        width: calc(100% - 100px);
    }
    nav.navbar {
        ul {
            width: calc(100% - 100px);
            max-width: calc(100% - 100px) !important;
        }
    }

    .activity-container {
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);

        .activities {
            margin-left: 0px;
        }
    }

    .activity-container .activities {
        margin-left: 0!important;
    }
}
