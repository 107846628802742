.label-list-container {
    display: flex;
    align-items: start;
    gap: 8px;

    .label-list {
        display: inline-flex;
        gap: 8px;
        font-family: system-ui;
        flex-wrap: wrap;
        // width: calc(100% - 43px);
    }
}
