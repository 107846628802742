.zoobbe-popover__content {
    .members-popover {
        background-color: var(--popover-background-color);
        border-radius: 8px;
        color: var(--white-text-color-alternative);
        padding: 12px;
        width: 285px;
        z-index: 1000;
        left: 0px;
        top: 45px;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            justify-content: center;

            h3 {
                margin: 0;
                font-weight: var(--popover-title-popover-font-weight);
                font-size: 14px;
                text-align: center;
                font-family: system-ui;
                color: var(--single-card-text-color);
            }

            &__close-btn {
                background: none;
                border: none;
                color: var(--white-text-color-alternative);
                font-size: 24px;
                cursor: pointer;
            }

            button.members-popover__close-btn {
                background: none;
                border: none;
                box-shadow: none;
                color: var(--white-text-color-alternative);
                font-size: 20px;
                cursor: pointer;
                border-radius: 5px;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.2s;

                &:hover {
                    background: var(--secondary-hover-background);
                }
            }
        }

        &__search {
            width: calc(100% - 16px);
            padding: 0 8px;
            margin-bottom: 8px;
            border-radius: var(--element-border-radius);
            outline: 1px solid var(--secondary-outline-color);
            background-color: var(--popover-background-color);
            color: var(--single-card-text-color);
            height: 35px;
            border: none;

            &:focus {
                outline: 2px solid var(--brand-color);
            }
        }

        &__section {
            margin-top: 16px;

            &:first-child {
                margin: 0;
            }

            h4 {
                margin-bottom: 15px;
                color: var(--single-card-text-color);
                font-size: 14px;
                font-family: system-ui;
            }
        }

        &__member {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            transition: 0.2s;
            padding: 5px 6px;
            font-weight: 500;
            font-size: 15px;
            -webkit-user-select: none;
            user-select: none;
            border-radius: 3px !important;

            &:hover {
                background: var(--single-card-side-action-bg-color);
                border-radius: var(--element-border-radius);
            }

            span {
                color: var(--single-card-text-color);
                flex: 1;
                margin-right: 0px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .action-icon {
                align-items: center;
                display: flex;

                span {
                    font-size: 20px;
                }

                span.material-symbols-outlined.close {
                    font-size: 18px;
                }
            }

            &__avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 8px;
            }

            &__initials {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: var(--member-initials-bg-color);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                font-size: 14px;
            }

            button.members-popover__remove-btn {
                background: none;
                border: none;
                box-shadow: none;
                color: var(--white-text-color-alternative);
                font-size: 20px;
                cursor: pointer;
                border-radius: 5px;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.2s;
            }
        }

        .member-list {
            display: inline-flex;
            align-items: center;
            gap: 3px;
            position: relative;
            .avatars {
                display: flex;
                align-items: center;
                position: relative;
            }
            .add-member-to-card {
                width: 35px;
                height: 35px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: var(--add-member-bg-color);
                border-radius: 50%;
                cursor: pointer;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
}
