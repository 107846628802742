:root {
  --zoobbe-brand-color: purple;
}

main#card-page header.zoobbe-header {
  position: static;
}

.zoobbe-header {
  display: flex;
  align-items: center;
  background-color: var(--workspace-background-color);
  // background-color: var(--top-header-background-color);
  padding: 8px 20px;
  color: var(--color-white);
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;

  .header-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  &__logo {
    font-size: 24px;
    margin-right: 20px;
    width: 140px;
    p {
      padding: 0;
      margin: 0;
    }
    .logo-beta {
      font-size: 0.6em; /* Makes the text smaller */
      color: #ff4500; /* Highlight color, adjust as needed */
      margin-left: 4px; /* Spacing from the main logo text */
      font-weight: bold; /* Makes it stand out */
      text-transform: uppercase; /* Ensures consistent capitalization */
      vertical-align: top; /* Aligns the text properly */
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--primary-text-color);
      font-weight: 500;
      font-size: 20px;
      gap: 5px;

      &::focus-visible {
        outline-offset: 0 !important;
      }
    }

    svg.zoobbe-logo {
      width: 100px;
      height: 30px;
    }
  }

  ul.header-nav-content {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 20px;
    margin-right: 20px;
    margin-left: 20px;

    li {
      display: flex;
      align-items: center;
      list-style: none;
      cursor: pointer;
      font-weight: 400;
      -webkit-user-select: none;
      user-select: none;
      color: var(--primary-text-color);
      background: var(--single-card-side-action-bg-color);
      /* padding: 8px; */
      border-radius: 4px;
      width: 35px;
      height: 35px;
      justify-content: center;
      transition: 0.2s;
      &:hover {
        background: var(--single-card-action-button-hover-color);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
    li.active {
      background: var(--notifications-icon-hover-color);
      span {
        color: var(--brand-color);
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 900px;
    margin-right: auto;

    &-item {
      background: none;
      border: none;
      color: var(--white-text-color-alternative);
      font-size: 16px;
      cursor: pointer;
      text-decoration: none;

      &--create {
        background-color: var(--brand-color);
        padding: 6px 12px;
        border-radius: 3px;
        color: var(--white);
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 8px;
      }
    }
  }

  &__search-container {
    display: flex;
    align-items: center;
    // background-color: var(--top-header-background-color);
    padding: 0px 10px;
    border-radius: 3px;
    margin-right: 20px;
    height: 32px;
    position: relative;
    padding-right: 0;
    outline: 1px solid var(--input-field-border-color);
    width: 500px;

    &__search-input {
      border: none;
      background: none;
      color: var(--white-text-color-alternative);
      outline: none;
      margin-left: 10px;
      height: 35px;
    }

    .search-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      span.material-symbols-outlined {
        color: var(--primary-text-color);
        font-size: 20px;
      }
    }
  }
  &__search-container.focused {
    outline: 2px solid var(--brand-color);
  }
  &__search-container.isOpen {
    width: 500px;
  }

  &__icons {
    display: flex;
    align-items: center;
    position: relative;
    gap: 20px;

    .search-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-notification {
      cursor: pointer;
      transition: 0.03s;
    }

    &-avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      cursor: pointer;
      // border: 5px solid transparent;
      transition: 0.03s;
      background-color: #fff;
      box-shadow: var(--profile-img-box-shadow);
    }
    // &-avatar.active {
    //   outline: 5px solid var(--profile-active-border-color);
    // }
  }
  .zoobbe-header__search-input {
    border: none;
    background: none;
    color: var(--white-text-color-alternative);
    outline: none;
    margin-left: 10px;
    width: 100%;
    height: 35px;
  }
}

.search-container {
  .search-icon {
    transition: 0.3s;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--single-card-side-action-bg-color);
    &:hover {
      background: var(--single-card-action-button-hover-color);
    }
    svg {
      width: 20px;
      height: 20px;
      color: var(--primary-text-color);
      cursor: pointer;
    }
  }

  .search-icon.active span {
    background-color: var(--notifications-icon-hover-color);
    color: var(--brand-color);
  }
}

@media screen and (max-width: 1180px) {
  .header-center {
    display: none;
  }
}
@media screen and (max-width: 1180px) {
  .header-center.active-search {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--modal-overlay-background);
    display: flex;
    justify-content: center;
    z-index: 3;
    overflow-x: auto;
    width: 100%;
    padding-top: 80px;
    .zoobbe-header__search-container {
      margin: 0;
      background: var(--input-background-color);
    }

    .search-results {
      top: 34px;
      max-width: calc(100% - 20px);
    }
  }
}

@media screen and (max-width: 768px) {
  .zoobbe-header ul.header-nav-content {
    display: none;
  }

  .zoobbe-header__icons {
    gap: 15px;
  }
  .zoobbe-header .header-right {
    gap: 15px;
  }

  span.label-text {
    display: none;
  }
  .zoobbe-header__menu-item--create {
    padding-left: 12px;
  }
}
