.activity-container {
    width: 100%;
    border-collapse: collapse;
    font-family: system-ui;
    color: #d9d9d9;
    max-width: 750px;
    margin: auto;

    .header-item {
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .activities {
        margin-left: -65px;
    }
}

.profile-page.card-loading {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;

    .top-profile {
        flex-shrink: 0;
    }

    .activity-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .table-body {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
