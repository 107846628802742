.priority-action {
    padding: 15px 10px;
    width: 180px;
    h2 {
        margin-bottom: 5px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
        margin-top: -2px;
    }

    .group {
        list-style: none;
        padding: 0;
        margin: 0;
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }

    li {
        border: none;
        border-radius: var(--element-border-radius);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;
        height: 35px;
        color: var(--single-card-text-color);
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 0 5px;
        width: calc(100% - 10px);

        &:hover {
            background-color: var(--single-card-side-action-bg-color);
        }

        svg {
            width: 18px;
            height: 18px;
        }

        svg.zoobbe-flag-low {
            fill: rgb(96, 96, 96);
        }
        svg.zoobbe-flag-high {
            fill: rgb(255, 197, 61);
        }
        svg.zoobbe-flag-normal {
            fill: #3e63dd;
        }
        svg.zoobbe-flag-urgent {
            fill: rgb(255, 133, 137);
        }

        span {
            font-size: 18px;
        }
    }
}
