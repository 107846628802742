.nav-recent__search-results {
    background-color: var(--top-header-background-color);
    color: #d0d0d0;
    padding: 15px 10px;
    width: 280px;
    position: absolute;
    z-index: 3;
    left: -10px;
    top: 4px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid var(--nav-popover-board-color);

    .category {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            font-size: 12px;
            color: #888;
            margin-bottom: 10px;
            text-transform: uppercase;
            margin-top: 0;
            padding: 0 5px;
        }

        .result-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding: 5px 20px;
            width: 100%;
            cursor: pointer;
            margin-bottom: 0;
            margin-left: -15px;
            position: relative;
            gap: 12px;

            a.card-permalink,
            a.board-permalink {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                border: 0;
                width: 100%;
                display: block;
                height: 100%;
            }

            &:hover {
                background: var(--hover-option-bg-color);
            }

            .icon {
                width: 40px;
                height: 40px;
                background-color: #444;
                border-radius: 8px;
                // margin-right: 12px;
                background-size: cover;
                background-position: center;
                overflow: hidden;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--select-option-hover-background);
                }

                svg {
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    z-index: 1;
                }

                &.card-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: var(--primary-text-color);
                    background: var(--hover-option-bg-color); // Add an icon as per your design

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            .content {
                width: calc(100% - 55px);

                .title {
                    font-size: 14px;
                    color: var(--primary-text-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 5px;
                    font-weight: 500;
                    max-width: 450px;
                    word-wrap: break-word;
                    font-weight: 600;
                }

                .subtitle {
                    font-size: 12px;
                    color: #888;
                    margin: 0;
                }
            }
        }
        .result-item.active {
            background: var(--hover-option-bg-color);
            cursor: auto;
        }
    }

    section.category.noting-found {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        font-size: 15px;
        line-height: 1.4rem;
        font-weight: 400;

        span.material-icons {
            font-size: 70px;
            color: #686666;
        }
    }

    .view-all {
        text-align: center;

        a {
            font-size: 14px;
            color: var(--notification-item-unread-color);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

div#navWorkspaces,
div#navRecent,
div#navStarred {
    // position: fixed;
    .zoobbe-popover__header {
        display: none;
    }
}
