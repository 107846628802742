.zoobbe-checklist {
    border-radius: 8px;
    width: 300px;
    width: calc(100% + 40px);
    margin-left: -40px;

    .checklist-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h3 {
            margin-bottom: 15px;
            position: relative;
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 16px;
            font-weight: var(--single-section-header-font-weight);
        }

        input {
            margin-right: 8px;
        }

        .delete-btn {
            border: none;
            padding: 4px 8px;
            border-radius: var(--element-border-radius);
            cursor: pointer;
        }
    }

    .progress-bar {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 14px;
        position: relative;

        .progress {
            background-color: var(--checklist-fill-progressbar-bg-color);
            height: 8px;
            width: 100%;
            border-radius: var(--element-border-radius);
            position: relative;
            margin-right: 8px;

            &::after {
                content: "";
                display: block;
                background-color: var(--brand-color);
                color: var(--white);
                height: 100%;
                width: 0%;
                border-radius: var(--element-border-radius);
            }
        }

        span {
            font-size: 12px;
        }
    }

    .checklist-items {
        .checklist-item {
            display: flex;
            align-items: start;
            user-select: none;
            margin-bottom: 5px;
            .checklist-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 0px 12px;
                border-radius: 15px;
                margin-top: -2px;
                padding-right: 8px;

                span.material-symbols-outlined {
                    opacity: 0;
                    border: 1px solid var(--single-card-action-button-hover-color);
                    border-radius: 15px;
                    cursor: pointer;
                    padding: 2px;
                    font-size: 20px;

                    &:hover {
                        background-color: var(--single-card-action-button-hover-color);
                    }
                }

                &:hover {
                    background: var(--single-card-side-action-bg-color);
                    span {
                        opacity: 1;
                    }
                }
            }

            label {
                cursor: pointer;
                transition: 0.2s;
                width: 100%;
                margin-right: auto;
                color: var(--single-card-text-color);
                word-break: break-word;
                padding: 8px 0;
                line-height: 1.6em;
                font-size: 16px;
                p,
                ol,
                ul {
                    padding: 0;
                    margin: 0;
                }
                ol,
                ul {
                    padding-left: 20px;
                }
                a {
                    color: var(--primary-text-link-color);
                    text-decoration: none;
                }

                font-family: system-ui;
            }

            button.delete-item-btn {
                border: none;
                cursor: pointer;
                border-radius: 5px;
                font-size: 15px;
                margin-top: 12px;
                color: var(--single-card-text-color);
                background: none;
            }

            input {
                margin-right: 8px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-top: 12px;
            }

            .checkbox-container {
                margin-top: 6px;
            }
        }
        .checklist-item.checked label {
            text-decoration: line-through;
            word-break: break-word;
            a {
                text-decoration: line-through;
            }
        }
    }

    .add-item {
        display: flex;
        align-items: center;
        margin-top: 10px;

        input {
            flex: 1;
            border-radius: var(--element-border-radius);
            padding: 6px;
            margin-right: 6px;

            color: #172b4d;
            font-family: system-ui;
        }

        button {
            background-color: var(--brand-color);
            border: none;
            color: #c9d1d9;
            padding: 6px 10px;
            border-radius: var(--element-border-radius);
            cursor: pointer;

            &.cancel-btn {
                margin-left: 4px;

                &:hover {
                    background-color: #30363d;
                }
            }
        }
    }

    .checklist-footer {
        display: flex;
        justify-content: space-between;

        .left-button {
            display: flex;
            align-items: center;
            gap: 10px;

            .add-btn {
                padding: 0px 15px;
                background-color: var(--single-card-side-action-bg-color);
                color: var(--primary-text-color);
                border: none;
                border-radius: var(--element-border-radius);
                cursor: pointer;
                font-weight: 600;
                height: 35px;

                &:hover {
                    background-color: var(--single-card-action-button-hover-color);
                }
            }
            .cancel-btn {
                background: none;
                color: var(--single-card-text-color);
                font-weight: 600;
                border: none;
                border-radius: var(--element-border-radius);
                cursor: pointer;
                display: flex;
                padding: 0px 15px;
                align-items: center;
                height: 35px;
                &:hover {
                    background-color: var(--single-card-side-action-bg-color);
                }
            }
        }

        button {
            border: none;
            padding: 6px 10px;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            background-color: none;
        }
    }
}

.checklist-item.currently-editing .checklist-footer-container {
    width: 100%;
    margin-bottom: 20px;
    background: var(--single-card-side-action-bg-color);
    padding: 12px;
    border-radius: 12px;
}

.checklist-item.currently-editing {
    align-items: start !important;

    .add-item {
        margin-top: 0px;
    }
}

.add-new-item-btn {
    background-color: var(--single-card-side-action-bg-color);
    border: none;
    border-radius: var(--element-border-radius);
    box-shadow: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui;
    font-size: 14px;
    font-weight: 400;
    font-weight: 500;
    justify-content: center;
    line-height: 20px;
    padding: 0px 12px;
    text-decoration: none;
    transition-duration: 85ms;
    transition-timing-function: ease;
    white-space: normal;
    margin-top: 20px;
    margin-left: 40px;
    color: var(--single-card-text-color);
    font-family: system-ui;
    height: 35px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--single-card-action-button-hover-color);
    }
}

.checklist-footer-container {
    .textarea-wrapper {
        width: 100%;
        padding: 5px 6px;
        background-color: var(--popover-background-color);
        outline: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        textarea {
            border-radius: var(--element-border-radius);
            box-shadow: none;
            font-size: 16px;
            resize: none;
            width: 100%;
            overflow: hidden;
            font-family: system-ui;
            background-color: var(--input-background-color);
            color: var(--single-card-text-color);
            font-family: system-ui;
            border: none;
            padding: 0;
            line-height: 1.4rem;
            height: 22px;
            font-size: 15px;
            font-weight: 500;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .textarea-wrapper.focused {
        outline: 2px solid var(--focus-outline-color);
    }
}

.checklist-item.currently-editing {
    .textarea-wrapper {
        margin-bottom: 5px;
    }
}

.checklist-footer-container textarea::placeholder {
    font-size: 15px;
}

.checklist-progress-bar {
    width: calc(100% - 40px);
    background: var(--checklist-progressbar-bg-color);
    border-radius: 15px;
    left: 40px;
    position: absolute;
    transition: 0.1s;
}

.checklist-footer-container {
    width: calc(100% - 40px);
    margin-left: auto;
}

.checklist-more-buttons {
    display: flex;
    button {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        border: none;
        padding: 0px 14px 0 10px;
        background: var(--single-card-side-action-bg-color);
        margin-left: 10px;
        cursor: pointer;
        color: var(--single-card-text-color);
        border-radius: 3px;
        transition: 0.2s;
        height: 35px;
        font-family: system-ui;
        font-weight: 500;

        span {
            color: var(--single-card-text-color);
            font-size: 18px;
        }
        &:hover {
            background-color: var(--single-card-action-button-hover-color);
        }
    }
}
