:root[data-theme="light"] {
    --skelton-highlight-color: #e0e0e0;
}

:root[data-theme="dark"] {
    --skelton-highlight-color: #17171a36;
}

.zoobbe-card-details .react-loading-skeleton {
    --base-color: var(--single-card-side-action-bg-color) !important;
    --highlight-color: var( --skelton-highlight-color);
}

.zoobbe-card-wrapper.skeleton {
    .card-heading {
        margin-bottom: 10px;
    }
    .card-members {
        display: flex;
        gap: 5px;
    }

    .watchers {
        display: flex;
        gap: 8px;
    }

    .zoobbe-attachment {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .zoobbe-duedate {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .zoobbe-card-details-info-right span.react-loading-skeleton {
        margin-bottom: 8px;
    }

    .zoobbe-card-content {
        display: flex;
        gap: 10px;
    }

    .zoobbe-card-modal-container .zoobbe-card-details-body {
        width: 100%;
    }

    .close-card-container {
        margin-left: auto;
    }
}

.zoobbe-card-wrapper.skeleton {
    .skeleton-avatar {
        width: 35px;
    }

    .skeleton-title {
        width: 570px;
    }

    .skeleton-close {
        width: 30px;
    }

    .skeleton-status {
        width: 200px;
    }

    .skeleton-member {
        width: 30px;
    }

    .skeleton-watcher-text {
        width: 80px;
    }

    .skeleton-watcher-icon {
        width: 30px;
    }

    .skeleton-watchers-extra {
        width: 80px;
    }

    .skeleton-duedate-label {
        width: 100px;
    }

    .skeleton-duedate-value {
        width: 200px;
    }

    .skeleton-description-title {
        width: 80px;
    }

    .skeleton-description-content {
        width: 100%;
    }

    .skeleton-attachment-thumbnail {
        width: 50px;
    }

    .skeleton-attachment-text {
        width: 370px;
    }

    .skeleton-checklist {
        width: 200px;
    }

    .skeleton-comment-avatar {
        width: 30px;
    }

    .skeleton-comment-box {
        width: 350px;
    }

    .skeleton-comment-lines {
        width: 390px;
    }

    .skeleton-sidebar-item {
        width: 150px;
    }
}

@media screen and (max-width: 768px) {
    .zoobbe-card-wrapper.skeleton {
        .skeleton-title {
            width: 420px;
        }
        .skeleton-attachment-text {
            width: 370px;
        }
        .skeleton-comment-box {
            width: 400px;
        }
        .skeleton-comment-lines {
            width: 440px;
        }
    }
}
@media screen and (max-width: 580px) {
    .zoobbe-card-wrapper.skeleton {
        .skeleton-title {
            width: 350px;
        }
        .skeleton-attachment-text {
            width: 300px;
        }
        .skeleton-comment-box {
            width: 300px;
        }
        .skeleton-comment-lines {
            width: 340px;
        }
    }
}
@media screen and (max-width: 480px) {
    .zoobbe-card-wrapper.skeleton {
        .skeleton-title {
            width: 200px;
        }
        .skeleton-attachment-text {
            width: 150px;
        }

        .skeleton-comment-box {
            width: 200px;
        }
        .skeleton-comment-lines {
            width: 240px;
        }
    }
}
