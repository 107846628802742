.sbm-share-board-modal {
    background-color: var(--popover-background-color);
    color: var(--white-text-color-alternative);
    padding: 20px;
    border-radius: 8px;
    width: 500px;

    .zoobbe-select {
        width: 140px;
    }

    .sbm-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin: 0;
        }

        .sbm-close-button {
            background: none;
            border: none;
            color: var(--white-text-color-alternative);
            font-size: 20px;
            cursor: pointer;
        }
    }

    .sbm-input-group {
        display: flex;
        align-items: start;
        margin: 20px 0 15px;
        gap: 10px;

        .sbm-email-input {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: self-start;
            background: var(--popover-background-color);
            width: calc(100% - 140px);
            padding: 5px;
            border-radius: var(--element-border-radius);
            gap: 5px;

            &:focus {
                background-color: var(--ds-background-input, var(--white-text-color-alternative));
                outline: 2px solid var(--outline-color);
            }

            input {
                flex: 1;
                border-radius: var(--element-border-radius);
                background-color: var(--popover-background-color);
                color: var(--white-text-color-alternative);
                border: none;
                height: 25px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                    border-color: var(--outline-color);
                }
            }

            .sbm-selected-email {
                display: inline-flex;
                align-items: center;
                background-color: var(--selected-email-bg-color);
                border-radius: var(--element-border-radius);
                padding: 5px;
                color: var(--primary-text-color);

                font-size: 14px;

                button {
                    background: none;
                    border: none;
                    color: var(--white-text-color-alternative);
                    font-size: 12px;
                    cursor: pointer;
                    display: flex;
                    padding-right: 0;

                    span {
                        font-size: 14px;
                    }
                }
            }

            .sbm-search-results {
                position: absolute;
                top: calc(100% + 3px);
                left: 0px;
                background-color: var(--popover-background-color);
                border: 1px solid var(--popover-border-color);
                border-radius: var(--element-border-radius);
                width: calc(100% - 22px);
                z-index: 1000;
                padding: 10px;
                max-height: 500px;
                overflow: auto;
                box-shadow: var(--popover-box-shadow);

                .no-user-message {
                    font-size: 13.5px;
                    line-height: 1.6;
                    font-family: system-ui;
                    padding: 0 8px;
                    font-weight: 500;
                }

                .sbm-search-result {
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: var(--search-result-hover-bg-color);
                    }

                    span {
                        display: block;
                    }
                }
            }
        }

        select {
            padding: 10px;
            margin-left: 10px;
            border: 1px solid var(--input-border-color);
            border-radius: var(--element-border-radius);
            background-color: var(--input-bg-color);
            color: var(--white-text-color-alternative);
            min-height: 42px;
        }

        .zoobbe-select-trigger {
            height: 35px;
            font-size: 14px;
            padding: 1px 15px;
        }

        .zoobbe-select .zoobbe-select-trigger span.arrow-down {
            top: 6px;
        }
        .zoobbe-select .zoobbe-select-option {
            font-size: 14px;
        }

        .zoobbe-select-trigger.active {
            color: var(--primary-text-color);

            border: none;
        }
        .zoobbe-select .zoobbe-select-option {
            padding: 0px 12px;
        }
    }

    .sbm-invite-button {
        padding: 0px 20px;
        background-color: var(--brand-color);
        border: none;
        border-radius: var(--element-border-radius);
        cursor: pointer;
        color: var(--white-text-color-alternative);
        font-size: 14px;
        height: 35px;
        margin: 0;
        width: 100%;
        margin-bottom: 20px;
        color: var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sbm-message-input {
        width: calc(100% - 20px);
        padding: 10px;
        border-radius: var(--element-border-radius);
        border: 1px solid var(--outline-color);
        background: var(--popover-background-color);
        color: var(--white-text-color-alternative);
        margin-bottom: 20px;
        font-family: system-ui;

        &:focus-visible {
            outline: 2px solid var(--brand-color);
        }
    }

    .sbm-invite-link {
        display: flex;
        align-items: center;
        color: var(--invite-link-text-color);
        font-size: 14px;
        margin-bottom: 15px;

        .invite-link-info {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .invite-link-icon {
            font-size: 18px;
            color: var(--invite-link-icon-color);
        }

        .invite-link-text {
            color: var(--invite-link-text-color);
        }

        .invite-link-actions {
            display: flex;
            gap: 8px;
        }

        .link-action {
            background: none;
            border: none;
            color: var(--brand-color);
            cursor: pointer;
            font-size: 14px;
            padding: 0;
            text-decoration: none;
        }

        .link-action:hover {
            text-decoration: underline;
        }

        .sbm-create-link-button {
            background: none;
            border: none;
            color: var(--brand-color);
            cursor: pointer;
            font-size: 14px;
            padding: 0;
            text-decoration: none;
        }

        .sbm-create-link-button:hover {
            text-decoration: underline;
        }
    }

    .sbm-tabs {
        display: flex;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--tab-border-color);

        button {
            background: none;
            border: none;
        }

        .sbm-tab {
            padding: 10px;
            text-align: center;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            color: var(--single-card-text-color);
        }

        .sbm-tab.active {
            color: var(--tab-active-color);
            border-bottom: 2px solid var(--tab-active-color);
        }
    }

    .sbm-workspace-members,
    .sbm-requests {
        h4 {
            margin: 0 0 10px 0;
        }

        .sbm-member {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .shared-members-name {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-right: auto;

                span.member-status {
                    font-size: 12px;
                    font-family: system-ui;
                    color: var(--member-status-color);
                }
                span.member-username {
                    font-size: 12px;
                    font-family: system-ui;
                    color: var(--member-username-color);
                    font-weight: 600;
                }
            }

            .re-invite {
                cursor: pointer;
                margin-right: 20px;
                display: flex;
                align-items: center;
                transition: 0.3s;

                &:hover span {
                    color: var(--reinvite-hover-color);
                }
                span {
                    font-size: 20px;
                    transition: 0.3s;
                }
            }
            .re-invite.loading {
                pointer-events: none;
                span {
                    color: var(--reinvite-loading-color);
                }
            }

            .members-options {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 12px;
                cursor: pointer;
                position: relative;
                border-radius: var(--element-border-radius);
                outline: 1px solid var(--outline-color);
                user-select: none;
                font-family: system-ui;
                font-size: 14px;
                height: 35px;
                color: var(--primary-text-color);
                padding: 0 15px;
                text-transform: capitalize;
                gap: 15px;
                padding-right: 10px;
                min-width: 100px;

                span.material-symbols-outlined {
                    font-size: 20px;
                }
            }

            .zoobbe-select {
                margin: 0;
                width: 120px;

                .admin-role {
                    margin-right: 10px;
                    font-weight: bold;
                    margin-bottom: 0;
                    width: 120px;

                    .zoobbe-select-trigger {
                        padding: 0px 12px;
                        background-color: var(--admin-role-bg-color);
                        font-weight: 400;
                        width: 90px;
                        color: var(--admin-role-text-color);

                        span {
                            right: 10px;
                        }

                        span.arrow-down {
                            top: 8px;
                        }
                    }

                    .zoobbe-select-option {
                        padding: 8px 12px;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: system-ui;
                    }
                }
            }
            .members-options.active {
                outline: 2px solid var(--brand-color);
                color: var(--brand-color);
            }

            .avatar {
                display: inline-flex;
            }

            .image-placeholder-container {
                margin-right: 10px;
            }

            .sbm-member-image,
            .image-placeholder {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--color-white);
            }

            .sbm-member-name {
                flex: 1;
                font-size: 14px;
                font-weight: 600;
            }

            .sbm-member-role {
                margin-right: 10px;
            }

            .sbm-member-role-dropdown {
                padding: 5px;
                border: 1px solid var(--input-border-color);
                border-radius: var(--element-border-radius);
                background-color: var(--input-bg-color);
                color: var(--white-text-color-alternative);
            }
        }
    }

    .mention-suggestion {
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        gap: 8px;
        margin: 0;
        min-width: 180px;
        outline: none;
    }

    .mention-suggestion:hover {
        background-color: var(--select-item-hover-background-color);
    }
}
