.settings-container {
    color: var(--primary-text-color);
    padding: 20px;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;

    .setting-row {
        display: flex;
        gap: 40px;
    }

    label.mode-label {
        font-size: 14px;
        margin-left: 5px;
    }

    .setting-label {
        width: 220px;

        h2 {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 500;
            color: var(--primary-text-color);
            margin-bottom: 10px;
            margin-top: 0;
        }

        p {
            font-size: 13.5px;
            font-weight: 400;
            color: #80868f;
            margin: 0;
            line-height: 1.4em;
        }
    }

    .notification-settings {
        h2 {
            margin-top: 0;
        }
        p {
            margin-top: 0;
            margin-bottom: 40px;
        }
        .settings-inner-container {
            color: var(--primary-text-color);
            max-width: 700px;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 60px;
        }
        .setting-label {
            width: 220px;

            h3 {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 500;
                color: var(--primary-text-color);
                margin-bottom: 10px;
                margin-top: 0;
                display: flex;
                align-items: center;
                gap: 10px;
            }

            p {
                font-size: 13.5px;
                font-weight: 400;
                color: #80868f;
                margin: 0;
                line-height: 1.4em;
            }
        }
    }

    .connect-with-slack-notifications {
        button {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            color: var(--primary-text-color);
            text-decoration: none;
            border: 1px solid var(--primary-text-color);
            padding: 10px 12px;
            border-radius: 8px;
            margin-bottom: 16px;
            background: none;
            box-shadow: none;
            cursor: pointer;

            span.material-symbols-outlined {
                font-size: 20px;
            }

            &:hover {
                color: #2eb67d;
                border: 1px solid #2eb67d;
            }
        }

        button.slack-connected {
            color: #2eb67d;
            border: 1px solid #2eb67d;

            &:hover {
                color: #e0245a;
                border: 1px solid #e0245a;
            }
        }
    }

    .save-btn {
        padding: 0px 20px;
        border: none;
        border-radius: var(--element-border-radius);
        background-color: var(--brand-color);
        color: var(--white);
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        font-weight: 600;
        width: calc(100% - 260px);

        &:hover {
            background-color: var(--hover-brand-color);
        }
    }

    .theme-colors {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        align-items: center;

        .color-option {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all 0.1s ease-in-out;

            &.selected {
                outline: 2px solid currentColor; // Uses the element’s color as outline
                outline-offset: 2px;

                .checkmark {
                    font-size: 18px;
                    color: var(--white);
                }
            }
        }
    }

    .appearance-options {
        display: flex;
        gap: 15px;
        align-items: center;

        .appearance-option {
            width: 60px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            transition: all 0.1s ease-in-out;
            border-radius: 2px;
            margin-bottom: 7px;

            &.selected {
                outline: 2px solid var(--brand-color);
                outline-offset: 3px;
            }

            .mode-preview {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                .checkmark {
                    font-size: 24x;
                }

                &.light {
                    background: #e5e9f0;
                    .checkmark {
                        color: #000;
                    }
                }
                &.dark {
                    background: #222;
                    .checkmark {
                        color: #fff;
                    }
                }
                &.system {
                    background: linear-gradient(to right, #e5e9f0 50%, #222 50%);
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .checkmark {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white; // Default to white for dark mode

                        // Use mix-blend-mode to adapt the checkmark color dynamically
                        mix-blend-mode: difference;
                    }
                }
            }
        }
    }

    .language-region-options {
        width: calc(100% - 260px);

        .group {
            margin-bottom: 15px;
        }

        .group h3 {
            margin-top: 0;
            font-size: 12px;
            font-weight: 400;
        }
        .search-select .search-select-trigger {
            height: 35px;
            padding: 0 8px;
            padding-right: 0;
            background: none;

            input {
                font-size: 12px;
                font-weight: 400;
            }
        }
        li.search-select-option {
            font-weight: 400;
        }
    }

    .toggle-container {
        display: flex;
        align-items: start;
        gap: 10px;
        font-size: 14px;
        color: var(--primary-text-color);
        margin-top: 10px;

        .toggle-switch {
            position: relative;
            display: inline-block;
            width: 34px;
            height: 20px;

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #444;
                transition: 0.4s;
                border-radius: 20px;

                &:before {
                    position: absolute;
                    content: "";
                    height: 14px;
                    width: 14px;
                    left: 3px;
                    bottom: 3px;
                    background-color: white;
                    transition: 0.4s;
                    border-radius: 50%;
                }
            }

            input:checked + .slider {
                background-color: var(--brand-color);
            }

            input:checked + .slider:before {
                transform: translateX(14px);
            }
        }
    }
}
