:root {
  --primary-color: #007bff;
  --background-color: #f8f9fa;
  --text-color: #333;
  --border-radius: 5px;
}

.archived-container {
  .tabs {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 0 16px;

    button {
      padding: 8px 16px;
      border: none;
      cursor: pointer;
      background: none;
      font-size: 14px;
      color: var(--text-color);
      border-bottom: 2px solid transparent;
      transition: 0.3s;
      width: 50%;
      color: var(--primary-text-color);
      font-weight: 600;

      &.active {
        // border-bottom: 2px solid var(--brand-color);
        color: var(--brand-color);
      }

      &:hover {
        color: var(--brand-color);
      }
    }
  }

  .search-box {
    padding: 0 16px;

    input {
      width: calc(100% - 20px);
      border-radius: var(--border-radius);
      margin-bottom: 15px;
      border-radius: var(--element-border-radius);
      outline: 1px solid var(--secondary-outline-color);
      background-color: var(--popover-background-color);
      color: var(--single-card-text-color);
      height: 35px;
      border: none;
      font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
      font-weight: 500;
      padding: 0 8px;
    }
  }
  .search-box.focused {
    input {
      outline: 2px solid var(--brand-color);
    }
  }
  .content {
    padding: 0 10px;
    height: calc(100vh - 300px);
    overflow: auto;
  }

  ol.arhived-cards {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-top: 5px;
      .card-pemalink {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }
    }

    .card-pemalink-wrapper {
      position: relative;
      text-decoration: none;
      color: inherit;
      margin-bottom: 3px;
      display: block;
      border-radius: 10px;
      border: 2px solid transparent;
      overflow: hidden;

      // .zoobbe-card {
      //   padding-top: 40px;
      // }

      .archive-actions {
        display: flex;
        align-items: center;
      }

      .delete-archive-card,
      .send-to-board {
        position: relative;
        z-index: 1;
        cursor: pointer;
        // display: none;
        .send-to-board-spinner {
          padding: 5px;
        }

        span {
          color: var(--single-card-text-color);
          font-size: 16px;
          padding: 5px;
          border-radius: 50px;
          transition: 0.3s;
          // background-color: var(--small-element-background-color);
          // background: var(--single-card-action-button-hover-color);

          &:hover {
            background: var(--single-card-action-button-hover-color);
          }
        }
        // .delete-archive-card,
        // .send-to-board {
        //   position: absolute;
        //   z-index: 1;
        //   top: 5px;
        //   cursor: pointer;
        //   // display: none;
        //   .send-to-board-spinner {
        //     padding: 5px;
        //   }

        //   span {
        //     color: var(--single-card-text-color);
        //     font-size: 16px;
        //     padding: 5px;
        //     border-radius: 50px;
        //     transition: 0.3s;
        //     // background-color: var(--small-element-background-color);
        //     // background: var(--single-card-action-button-hover-color);

        //     &:hover {
        //       background: var(--single-card-action-button-hover-color);
        //     }
        //   }
      }
      .delete-archive-card {
        // right: 5px;
        span {
          color: var(--delete-button-bg-color);

          &:hover {
            background: var(--delete-button-bg-color);
            color: var(--delete-button-text-color);
          }
        }
      }
      // .delete-archive-card {
      //   right: 5px;
      //   span {
      //     color: var(--delete-button-bg-color);

      //     &:hover {
      //       background: var(--delete-button-bg-color);
      //       color: var(--delete-button-text-color);
      //     }
      //   }
      // }

      .send-to-board {
        span {
          color: green;

          &:hover {
            background: green;
            color: var(--delete-button-text-color);
          }
        }
      }
      // .send-to-board {
      //   right: 35px;
      //   span {
      //     color: green;

      //     &:hover {
      //       background: green;
      //       color: var(--delete-button-text-color);
      //     }
      //   }
      // }

      &:hover {
        border-color: var(--focus-outline-color);

        .deletee-archive-card,
        .send-to-board {
          display: block;
        }
      }
    }
  }

  .content {
    .cards,
    .lists {
      // padding: 10px;
      // background: white;
      // border-radius: var(--border-radius);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .list-item {
        background: var(--card-background-color);
        border-radius: 6px;
        padding: 12px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        h3 {
          margin-bottom: 0px;
          margin-top: 0;
          font-size: 14px;
          line-height: 1.4;
          color: var(--single-card-text-color);
          word-break: break-word;
          font-weight: 600;
          font-family: system-ui;
          max-width: calc(100% - 50px);
        }
        .send-to-board {
          position: absolute;
          z-index: 1;
          top: 5px;
          cursor: pointer;
          right: 35px;
          .send-to-board-spinner {
            padding: 5px;
          }
          span {
            font-size: 16px;
            padding: 5px;
            border-radius: 50px;
            transition: 0.3s;
            color: green;
            &:hover {
              background: green;
              color: var(--delete-button-text-color);
            }
          }
        }
        .delete-archive-list {
          position: absolute;
          z-index: 1;
          top: 5px;
          cursor: pointer;
          right: 5px;
          span {
            font-size: 16px;
            padding: 5px;
            border-radius: 50px;
            transition: 0.3s;
            color: var(--delete-button-bg-color);

            &:hover {
              background: var(--delete-button-bg-color);
              color: var(--delete-button-text-color);
            }
          }
        }
      }
    }
  }
}
.archived-container.nav-archived-popover {
  max-width: 300px;
  width: 300px;
  padding-top: 12px;

  .menu-title {
    font-size: 15px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
