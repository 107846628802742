.delete-action {
    padding: 15px;
    width: 280px;

    h2 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }

    p.warning-message {
        font-size: 15px;
        line-height: 1.4em;
        font-family: system-ui;
        font-weight: 500;
    }

    button {
        padding: 0px 20px;
        background-color: var(--button-background-color);
        border: none;
        border-radius: var(--element-border-radius);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;
        height: 35px;
        color: var(--single-card-side-action-bg-color);
        font-weight: 600;

        &:hover {
            background-color: var(--button-hover-background-color);
        }
    }
}
