.more-action {
    padding: 15px;
    width: 280px;
    h2 {
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        font-weight: var(--popover-title-popover-font-weight);
        font-family: system-ui;
    }

    .group {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        .zoobbe-select-trigger {
            outline: 1px solid var(--secondary-outline-color);
        }
        h3 {
            font-size: 12px;
            margin-top: 0;
        }
    }

    p.warning-message {
        font-size: 15px;
        line-height: 1.4em;
        font-family: system-ui;
        font-weight: 500;
    }

    li {
        border: none;
        border-radius: var(--element-border-radius);
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;
        height: 35px;
        color: var(--single-card-text-color);
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 6px;

        &:hover {
            color: var(--single-card-action-button-hover-color);
        }

        span {
            font-size: 18px;
        }
    }
    li.delete-item {
        &:hover {
            color: #f87168;
        }
    }
}
