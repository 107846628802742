div#boardVisibility {
    margin-top: 10px;

    .change-visibility {
        background-color: var(--popover-background-color);
        color: #e4e4e4;
        border-radius: 8px;
        max-width: 400px;
        padding: 16px 0;

        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            h3 {
                font-size: 14px;
                text-align: center;
                font-weight: 600;
                font-family: system-ui;
                margin: 0;
                color: var(--single-card-text-color);
            }
        }

        &__options {
            list-style: none;
            margin: 0;
            padding: 0;

            .change-visibility__option {
                display: flex;
                align-items: flex-start;
                padding: 12px;
                cursor: pointer;
                transition: background 0.3s;
                gap: 10px;

                &:hover {
                    background: var(--hover-option-bg-color);
                }

                &.selected {
                    background: rgba(11, 102, 255, 0.0509803922);
                    pointer-events: none;
                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                }

                &.disabled {
                    cursor: not-allowed;
                    color: #9e9e9e;

                    &:hover {
                        background: none;
                    }
                }

                .material-symbols-outlined {
                    font-size: 1.2rem;
                    color: var(--primary-text-color);
                }

                .details {
                    h3 {
                        margin: 0;
                        font-size: 0.85em;
                        font-weight: 600;
                        color: var(--primary-text-color);
                    }

                    p {
                        margin: 5px 0 0;
                        font-size: 0.85rem;
                        color: rgba(159, 173, 188, 0.7803921569);
                        font-weight: 500;
                        line-height: 1.4em;
                    }
                }
            }
        }
    }
}
