.snackbar {
    position: fixed;
    bottom: 20px;
    left: 20px;
    // transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 0px 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    height: 60px;
    padding-top: 25px;
    transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;

    &.snackbar-exit {
        opacity: 0;
        transform: translateX(-50%) translateY(10px);
    }

    .snackbar-content {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .progress-bar-container {
        width: 250px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .progress-bar-header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .icon-bar {
        gap: 8px;
        position: relative;

        .uploading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .import-icon {
        font-size: 20px;
        color: var(--brand-color);
    }

    .success-icon {
        font-size: 24px;
        color: #4caf50;
    }

    .progress-bar {
        width: 100%;
        height: 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 3px;
        overflow: hidden;
        margin-top: 0px;
    }

    .progress-bar-fill {
        height: 100%;
        background: var(--brand-color);
        transition: width 0.3s ease-in-out;
    }

    .progress-bar-percentage {
        font-size: 14px;
    }

    .snackbar-close-btn {
        background: none;
        border: none;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin-left: auto;
        transition: color 0.2s;
        position: absolute;
        top: 5px;
        right: 5px;

        &:hover {
            color: #ff4d4d;
        }
    }
}
