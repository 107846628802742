.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 5px;

    input[type="checkbox"] {
        display: none;
    }
}

.checkbox {
    width: 14px;
    height: 14px;
    background: var(--checkbox-background-color);
    border: 2px solid var(--checkbox-background-color);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
    

    svg {
        visibility: hidden;
        color: var(--white-text-color-alternative);
        width: 16px;
        height: 16px;
    }

    span.material-symbols-outlined {
        color: var(--white-text-color-alternative);
        font-size: 16px;
    }

    span.material-symbols-outlined {
        padding: 5px;
        background: rebeccapurple;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
    }
}

.checkbox.checked {
    background: var(--checkbox-background-color);

    svg {
        visibility: visible;
    }
}

// .label {
//     margin-left: 8px;
// }
