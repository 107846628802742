.export-import-container {
    margin: 2rem;
    max-width: 500px;
    margin: auto;
    margin-top: 60px;

    .zoobbe-select {
        margin-bottom: 20px;

        .zoobbe-select-option {
            height: 40px;
        }
    }

    .buttons {
        display: flex;
        gap: 1rem;

        span.material-symbols-outlined {
            font-size: 20px;
        }

        button {
            padding: 2px 18px;
            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            &.export-btn {
                background-color: #3498db;
                color: var(--color-white);

                &:hover:not(:disabled) {
                    background-color: #2980b9;
                }
            }

            &.import-btn {
                background-color: #2ecc71;
                color: var(--color-white);

                &:hover:not(:disabled) {
                    background-color: #27ae60;
                }
            }
        }

        .import-label {
            position: relative;
            display: inline-block;
            padding: 0.8rem 1.5rem;
            font-size: 1rem;
            background-color: #f39c12;
            color: var(--color-white);
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            font-size: 1rem;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            &:hover {
                background-color: #e67e22;
            }

            input {
                display: none;
            }
        }
    }

    .uploaded-file-name {
        color: var(--white-text-color-alternative);
        text-align: left;
        width: 100%;
        margin-bottom: 20px;
        // color: #c4bebe;
        color: var(--primary-text-color);
    }

    .create-user-checkbox label {
        display: inline-flex;
        align-items: center;
        margin-bottom: 25px;
        gap: 5px;
        font-family: system-ui;
        font-weight: 600;
        color: #797a7c;
        cursor: pointer;
        user-select: none;
    }
}

.select-workspace-to-import {
    width: 100%;
    color: #3498db;

    label {
        align-self: flex-start;
        margin-bottom: 0.8rem;
        font-family: system-ui;
        font-weight: 600;
        color: #797a7c;
        margin-bottom: 10px;
        display: inline-block;
    }

    .zoobbe-select-trigger {
        height: 40px;
    }

    .zoobbe-select .zoobbe-select-trigger span.arrow-down {
        top: 8px;
    }
}

.export-import-container {
    .logs-container {
        margin-top: 20px;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        .log-entry {
            margin-bottom: 20px;
            padding: 10px;
            border-bottom: 1px solid #e0e0e0;

            &:last-child {
                border-bottom: none;
            }

            .log-title {
                font-weight: bold;
                margin-bottom: 5px;
                color: var(--color-white);
            }

            .progress-bar {
                height: 20px;
                border-radius: 4px;
                background-color: #007bff;
                margin: 10px 0;
                color: white;
                line-height: 20px;
                text-align: center;
                transition: width 0.3s;
            }

            .log-details {
                font-size: 14px;
                color: #555;

                strong {
                    color: var(--scrolbar-thumb-background-color);
                }
            }
        }
    }
}

.export-import-container {
    .progress-bar-container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .progress-bar-header {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-size: 14px;
            justify-content: space-between;

            .import-titles {
                display: flex;
                align-items: center;
                gap: 10px;
                color: var(--primary-text-color);
                font-size: 15px;
                font-weight: 600;
                width: calc(100% - 100px);
                font-family:
                    system-ui,
                    -apple-system,
                    BlinkMacSystemFont,
                    "Segoe UI",
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    "Open Sans",
                    "Helvetica Neue",
                    sans-serif;
                .icon-bar {
                    position: relative;
                    /* width: 40px; */
                    display: flex;
                    color: green;
                    .material-symbols-outlined.import-icon {
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        color: #3498dbb3;
                    }
                }
                .title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .import-titles.import-success {
                margin-bottom: 10px;
            }
            .items-imported {
                color: var(--primary-text-color);
                font-size: 15px;
                font-weight: 600;
                font-family:
                    system-ui,
                    -apple-system,
                    BlinkMacSystemFont,
                    "Segoe UI",
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    "Open Sans",
                    "Helvetica Neue",
                    sans-serif;
            }

            .file-icon {
                width: 24px;
                height: 24px;
                background-color: #e0e0e0;
                border-radius: 4px;
                margin-right: 8px;
            }

            .file-size {
                margin-left: auto;
                font-size: 12px;
                color: #666;
            }
        }

        .progress-bar {
            position: relative;
            width: 100%;
            height: 10px;
            background-color: #e0e0e0;
            border-radius: 5px;
            overflow: hidden;

            .progress-bar-fill {
                height: 100%;
                background-color: #6a0dad;
                transition: width 0.4s ease;
            }
        }

        .progress-bar-percentage {
            margin-top: 4px;
            margin-top: 8px;
            font-size: 12px;
            color: #666;
            text-align: right;
            font-weight: 900;
        }
    }
}
