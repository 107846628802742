$background-color: var(--popover-background-color);
$text-color: var(--single-card-text-color);
$link-color: var(--brand-color);
$secondary-text-color: var(--primary-text-color);
$border-color: var(--popover-background-color);
$sidebar-width: 300px;
$profile-picture-size: 50px;
$border-radius: 8px;

.zoobbe-profile {
    width: 300px;
    background-color: var(--popover-background-color);
    border-radius: 8px;
    position: absolute;
    right: -20px;
    top: 40px;
    z-index: 4;
    box-shadow: var(--popover-box-shadow);
    border: 1px solid var(--popover-border-color);
    
    .account-section {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding: 20px 0 0px 20px;
        gap: 10px;
        .profile-picture {
            width: $profile-picture-size;
            height: $profile-picture-size;
            border-radius: 50%;
        }

        .account-info {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .username {
                font-weight: bold;
                margin: 0;
                color: var(--primary-text-color);
            }

            .email {
                margin: 0;
                color: $secondary-text-color;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .manage-account-section {
        border-bottom: 1px solid var(--popover-border-color);
        margin-bottom: 20px;
        // padding: 0 20px 20px;

        .manage-account {
            display: block;
            margin-top: 5px;
            color: $link-color;
            text-decoration: none;
        }
    }

    .menu-section,
    .other-section {
        border-bottom: 1px solid var(--popover-border-color);

        .menu-title {
            font-weight: bold;
            margin-bottom: 10px;
            padding: 0 20px;
            color: var(--primary-text-color);
        }

        ul {
            list-style: none;
            padding: 10px 0;
            margin: 0;

            li {
                a {
                    color: var(--single-card-text-color);
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: system-ui;
                    display: block;
                    padding: 10px 20px;

                    &:hover {
                        // color: $link-color;
                        background-color: var(--profile-card-action-background-hover-color);
                    }
                }
            }
        }
    }
    .other-section {
        margin-bottom: 0px;
    }
    .logout-section {
        .menu-title {
            font-weight: bold;
            margin-bottom: 10px;
            padding: 0 20px;
        }

        ul {
            list-style: none;
            padding: 10px 0;
            margin: 0;

            li {
                a {
                    color: var(--single-card-text-color);
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: system-ui;
                    display: block;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;

                    gap: 10px;

                    span.material-symbols-outlined {
                        font-size: 18px;
                    }

                    &:hover {
                        color: $link-color;
                        background-color: var(--scrolbar-thumb-background-color);
                    }
                }
            }
        }
    }

    .workspace-section {
        .create-workspace,
        .export-import-board {
            color: var(--brand-color);
            text-decoration: none;
            background: none;
            box-shadow: none;
            border: none;
            font-size: 15px;
            font-weight: 600;
            width: 100%;
            text-align: left;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            padding-left: 0;
            // border-bottom: 1px solid var(--popover-border-color);
            padding: 20px;
            span.material-symbols-outlined {
                font-size: 16px;
                font-weight: 600;
            }
        }

        a.export-import-board {
            padding: 15px 20px;
        }
    }

    .other-section {
        ul {
            border-top: 1px solid $border-color;
            padding: 5px 0;
            margin: 0;

            li {
                a {
                    color: $text-color;
                    text-decoration: none;

                    &:hover {
                        color: $link-color;
                    }
                }
            }
        }
    }
}

// User Profile Page

.profile-page {
    display: flex;
    color: var(--white-text-color-alternative);
    background-color: var(--workspace-background-color);
    flex-direction: column;

    .bottom-profile-content {
        display: flex;
        width: 600px;
        margin: 30px auto;
    }

    .sidebar {
        width: 220px;
        background-color: #2c2c2e;
        padding: 20px 0;
        border-radius: 8px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                a {
                    color: var(--white-text-color-alternative);
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: system-ui;
                    display: block;
                    padding: 12px 20px;
                    text-decoration: none;

                    &:hover {
                        color: var(--brand-color);
                        background-color: var(--scrolbar-thumb-background-color);
                    }
                }
            }
        }
    }

    .content {
        flex-grow: 1;
        padding: 20px;
        width: calc(100% - 220px);

        h1 {
            font-size: 24px;
            margin-bottom: 10px;
            margin-top: 0;
            color: var(--primary-text-color);
        }

        p {
            font-size: 14px;
            margin-bottom: 20px;
            line-height: 1.6;
            color: var(--primary-text-color);
        }

        a {
            color: var(--notification-item-unread-color);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .about-section {
            h2 {
                font-size: 18px;
                margin-bottom: 20px;
                border-bottom: 1px solid var(--scrolbar-thumb-background-color);
                padding-bottom: 20px;
                color: var(--primary-text-color);
            }

            .form-group {
                margin-bottom: 15px;

                label {
                    display: block;
                    font-size: 14px;
                    margin-bottom: 12px;
                    color: var(--primary-text-color);
                    font-weight: 700;
                }

                input,
                textarea {
                    width: calc(100% - 20px);
                    padding: 10px;
                    border: 1px solid #222;
                    border-radius: var(--element-border-radius);
                    background-color: var(--workspace-background-color);
                    color: var(--primary-text-color);
                    height: 20px;
                    font-family: system-ui;

                    &:focus {
                        outline: 2px solid var(--brand-color);
                    }
                }

                textarea {
                    height: 100px;
                    width: calc(100% - 20px);
                }
                .profile-picture {
                    padding: 20px 0;
                    img {
                        width: 180px;
                        height: 180px;
                        object-fit: cover;
                    }
                }
            }

            button {
                padding: 0px 20px;
                border: none;
                border-radius: var(--element-border-radius);
                background-color: var(--brand-color);
                color: var(--white);
                cursor: pointer;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                font-weight: 600;
                &:hover {
                    background-color: var(--hover-brand-color);
                }
            }
            .choose-file-button {
                gap: 5px;
                font-weight: 500;
                background-color: transparent;
                padding: 0;
                justify-content: start;
                margin-top: 20px;
                color: var(--primary-text-color);
                max-width: 135px;
                align-items: center;
                display: flex;
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                &:hover {
                    background-color: transparent;
                }

                span {
                    font-size: 16px;
                }
            }
        }
    }
}
