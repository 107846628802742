:root {
    --color-white: #fff;
    --white: #fff;
    --black: #000;
    --primary-color: #181717;
    --popover-background-color: #181717;
    --create-form-background-color: #181717;
    --single-card-background-color: #202123;
    --single-card-side-action-bg-color: #2b2c2d;
    --single-card-side-action-font-weight: 500;
    --single-card-action-button-hover-color: #353b40;
    // --single-card-text-color: #9aa0a6;
    --single-card-text-color: #bec7d2;
    --brand-color: #0966ff;
    --secondary-brand-color: #38a801;
    --button-disabled-color: #0966ff9c;
    --hover-brand-color: #0966ff9c;
    --brand-hover-bg-color: rgba(11, 102, 255, 0.0509803922);
    --selected-option-text-color: #0b66ff;
    --select-option-hover-background: #0b66ff0d;
    --outline-color: #444;
    --secondary-outline-color: #444;
    --editor-outline-color: #44444475;
    --focus-outline-color: var(--brand-color);
    --input-border-color: #444;
    --element-border-radius: 2px;
    --editor-border-radius: 8px;
    --input-background-color: #181717;
    --popover-border-color: #242526;
    --popover-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // --primary-text-color: #b6c2cf;
    // --primary-text-color: #9aa0a6;
    --primary-text-color: #bec7d2;
    --primary-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    --primary-text-link-color: #0079bf;
    --checklist-progressbar-bg-color: #353b40;
    --checklist-fill-progressbar-bg-color: #0052cc82;
    --button-background-color-1: #0052cc;
    --single-section-header-font-weight: 600;
    --popover-title-popover-font-weight: 500;

    --white-text-color-alternative: var(--primary-text-color);

    --input-selection-color: #2b2c2d;
    --input-selection-bgcolor: #6c757d;

    --label-text-color: #222;
    --label-font-weight: 600;
    --delete-button-bg-color: #f87168;
    --delete-button-text-color: #2b2c2d;
    --board-overlay-background-color: rgba(0, 0, 0, 0.2);
    --add-list-background-color: rgb(6, 6, 6);
    --add-list-background-hover-color: #08283b;
    --list-header-background-color: #06060796;
    --actionlist-background-color: #060606;
    --card-background-color: #171b1f;
    --board-option-button-background-color: #171b1f;
    --add-card-button-hover-background-color: rgb(23, 27, 32);
    --small-element-background-color: #171b20;
    --badge-background-color: #6c757d;
    --navbar-background-color: rgba(0, 0, 0, 0.2);
    --top-header-background-color: #1e1e1e;
    --profile-border-color: #3a3737;
    --profile-active-border-color: #233b5b;
    --notification-background-color: #3a3737;
    --filter-placeholder-background-color: #181717;
    --search-results-option-background-color: #060d11;
    --board-menu-item-background-color: #1a1a1a;
    --input-field-border-color: #333;
    --scrolbar-thumb-background-color: #333333;
    --filter-option-text-color: #cfd2d6;
    --checkbox-background-color: #222f39;
    --tooltip-border-color: #444;

    --workspace-background-color: #121217;
    --workspace-sidbar-item-hover-background-color: #d9dbdc;
    --workspace-icon-background-color: #242628;
    --board-item-background-color: #171b1e;
    --board-item-border-color: #171b1e;

    --editor-background-color: #101415;
    --editor-toolbar-background-color: #181818;
    --editor-toolbar-icon-color: #e8e8e8;
    --board-header-background-color: #323232;
    --nav-popover-board-color: #323232;
    --colaborators-tab-background-color: #3a3f47;

    --select-item-hover-background-color: #302d2d;
    --selected-item-background-color: #302d2d;
    --auth-form-background-color: #181717;
    --auth-form-body-background-color: #181717;

    --social-login-button-border-color: #434750;
    --social-login-text-color: #c0c7d1;
    --profile-card-action-background-hover-color: #272727;

    --skelton-base-color: #1f1f23;
    --skelton-highlight-color: #17171a36;

    --skeleton-inner-background-color: #141415;
    --skeleton-header-wrapper-background-color: #1a1a1b;
    --skeleton-board-header-color: #201e23;

    --menu-item-icon-background-color: #0966ff1a;
    --menu-divider-color: #2d2d2d;
    --background-box-hover-overlay-color: #ffffff24;
    --background-box-checkmark-color: #ffffff;
    --background-box-large-text-background-color: rgba(0, 0, 0, 0.5);
    --background-box-large-text-hover-color: #ffdd57;

    --admin-role-bg-color: #292b2f;
    --select-background-color: #40444b;
    --select-border-color: #555;
    --inactive-hover-color: #555;
    --re-invite-hover-color: #5f6467;
    --invite-link-color: #cfcfcf;
    --invite-link-text-color: #cfcfcf;
    --link-action-hover-color: #579dff;
    --sbm-tab-active-border-color: #579dff;
    --sbm-tab-active-color: #579dff;
    --member-username-color: #9aa0a680;
    --member-status-color: #9aa0a680;

    --notifications-icon-hover-color: #0966ff26;
    --notifications-panel-background-color: #181717;
    --notifications-panel-text-color: #c4bebe;
    --notification-item-hover-border-color: #232a32;
    --notification-item-unread-color: #1a73e8;
    --comment-box-background-color: #ff4444;
    --notification-date-color: #888888;
    --prev-notification-link-background-color: #414244;

    --modal-overlay-background: rgba(0, 0, 0, 0.85);
    --zoobbe-select-border-color: var(--input-border-color);
    --zoobbe-select-hover-background-color: var(--select-item-hover-background-color);
    --zoobbe-select-option-selected-background-color: var(--selected-item-background-color);
    --scrollbar-thumb-background: #333333a5;
    --scrollbar-track-background: transparent;
    --scrollbar-corner-background: var(--popover-background-color);

    --member-bg-color: #2c3338;
    --hover-bg-color: #555;
    --tab-active-color: #579dff;
    --tab-bottom-border-color: #3d4042;
    --inactive-text-color: #9aa0a680;
    --dropdown-bg-color: #40444b;
    --dropdown-text-color: #8c8d8e;

    --error-message-color: #ff4d4d; // New variable
    // --primary-color: #0056b3; // New variable

    --checkbox-border-color: #2e3646;

    --other-h2-color: #444;
    --other-a-color: #777;
    --editor-text-color: #000;
    --editor-placeholder-color: #9aa0a661;
    --editor-highlight-color: #999;
    --toolbar-item-disabled-color: #63666a73;
    --tree-view-background: #222;
    --editor-heading-h1-color: rgb(5, 5, 5);
    --editor-heading-h2-color: rgb(101, 103, 107);
    --editor-quote-border-color: rgb(206, 208, 212);
    --editor-quote-text-color: rgb(101, 103, 107);
    --typehead-popover-background-color: #302d2d;
    --typehead-popover-item-selected-color: #302d2d;
    --editor-image-resizer-background-color: rgb(60, 132, 244);
    --image-caption-background-color: #f4f4f4;
    --editor-toolbar-icon-color: #9aa0a685;
    --editor-code-border-right-color: #ccc;

    --editor-token-comment-color: slategray;
    --editor-token-punctuation-color: var(--editor-placeholder-color);
    /* Already defined */
    --editor-token-property-color: #905;
    --editor-token-selector-color: #690;
    --editor-token-operator-color: #9a6e3a;
    --editor-token-attr-color: #07a;
    --editor-token-variable-color: #e90;
    --editor-token-function-color: #dd4a68;
    --mention-background-color: #0748a8;
    --editor-toolbar-item-active-background-color: #181c28;

    --secondary-outline-color: #444; /* Outline color for select trigger */
    --button-background-color: #f87168; /* Background color for button */
    --button-hover-background-color: #f87268b7;

    --secondary-hover-background: #444; /* Hover background for close buttons */
    --member-initials-bg-color: #666; /* Background color for member initials */
    --add-member-bg-color: #ddd; /* Background color for add member button */

    --filter-text-color-muted: #a0a6b1; /* Used in .filter-container input */
    --filter-label-text-color: #6b717e; /* Used in .label */
    --filter-link-color: var(--brand-color); /* Used in .filter-options .show-more */
    --filter-icon-background: rebeccapurple; /* General icon background */
    --filter-icon-person-background: #663399; /* Specific to .person */
    --filter-icon-overdue-background: #ff0000; /* Specific to .overdue */
    --filter-icon-due-nextday-background: #029e02; /* Specific to .due-nextday */
    --filter-cards-count-text-color: #6c757d;

    --activity-text-color: #a1a1a1;

    --change-visibility-hover-bg-color: #242628; /* Hover background color */
    --selected-option-bg-color: rgba(11, 102, 255, 0.0509803922); /* Selected option background color */
    --disabled-option-color: #9e9e9e; /* Disabled option color */
    --option-details-text-color: rgba(159, 173, 188, 0.7803921569); /* Option details text color */
    --hover-option-bg-color: #242628;
    --watching-checkmark-background-color: #353b40;
    --selected-email-bg-color: #2c3338;
    --prev-notification-background-color: #3a3838;
    --editor-image-border-color: #444;
    --profile-img-box-shadow: 0 0 20px rgba(31, 28, 28, 0.8);
    --text-selection-bg-color: rgba(150, 150, 150, 0.4);
}

:root[data-theme="light"] {
    --color-white: #000; /* Opposite of white for dark text */
    --primary-color: #f9f9f9; /* Light background */
    --popover-background-color: #f9f9f9;
    --create-form-background-color: #ffffff;
    --single-card-background-color: #ffffff; /* Bright card background */
    --single-card-side-action-bg-color: #f3ecec; /* Softer background */
    --single-card-side-action-font-weight: 500;
    --single-card-action-button-hover-color: #e0e0e0;
    --single-card-text-color: #3a4b65; /* Dark text for light mode */
    --brand-color: #0052cc; /* Complementary brand color for light mode */
    --secondary-brand-color: #38a801;
    --button-disabled-color: #0052cc9c;
    --hover-brand-color: #0052cc9c;
    --brand-hover-bg-color: rgba(0, 82, 204, 0.05);
    --selected-option-text-color: #0b66ff;
    --select-option-hover-background: #0b66ff0d;
    --outline-color: #ddd; /* Softer outline color */
    --editor-outline-color: #ddd;
    --secondary-outline-color: #ddd; /* Adjusted purple shade */
    --focus-outline-color: var(--brand-color);
    --input-border-color: #ddd;
    --element-border-radius: 2px;
    --editor-border-radius: 8px;
    --input-background-color: #ffffff;
    --popover-border-color: #e0e0e0;
    --popover-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Lighter shadow */
    --primary-text-color: #3a4b65; /* Darker text for readability */
    --primary-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    --primary-text-link-color: #0079bf;
    --checklist-progressbar-bg-color: #d7e1ea;
    --checklist-fill-progressbar-bg-color: #0052cc82;
    --button-background-color-1: #0073e6; /* Slightly brighter button */
    --single-section-header-font-weight: 600;
    --popover-title-popover-font-weight: 500;

    --white-text-color-alternative: var(--primary-text-color);

    --input-selection-color: #2b2c2d;
    --input-selection-bgcolor: #6c757d;

    --label-text-color: #555; /* Medium contrast text */
    --label-font-weight: 600;
    --delete-button-bg-color: #ff6f61; /* Complementary delete button color */
    --delete-button-text-color: #ffffff;

    --board-overlay-background-color: rgba(0, 0, 0, 0.2);
    --add-list-background-color: rgb(245, 245, 245);
    --add-list-background-hover-color: #b3d7f0;
    --list-header-background-color: #ffffff;
    --actionlist-background-color: #ededed;
    --card-background-color: #ffffff;
    --board-option-button-background-color: #ffffff;
    --add-card-button-hover-background-color: #e0dbdb;
    --small-element-background-color: #e8edf3;
    --badge-background-color: #d6d9dc;
    --navbar-background-color: rgba(255, 255, 255, 0.2);
    --top-header-background-color: #ffffff;

    --profile-border-color: #c8c8c8;
    --profile-active-border-color: #adc4e6;
    --notification-background-color: #c8c8c8;
    --filter-placeholder-background-color: #f9f9f9;
    --search-results-option-background-color: #e0e4e8;
    --board-menu-item-background-color: #f5f5f5;
    --input-field-border-color: #c6c6c6;
    --scrolbar-thumb-background-color: #e0e0e0;
    --filter-option-text-color: #3a4b65;
    --checkbox-background-color: #e0e5e8;
    --tooltip-border-color: #d1d1d1;

    --workspace-background-color: #ffffff;
    --workspace-sidbar-item-hover-background-color: #242728;
    --workspace-icon-background-color: #f1f3f5;
    --board-item-background-color: #f7f7f7;
    --board-item-border-color: #e4e7eb;

    --editor-background-color: #fcfcfc;
    --editor-toolbar-background-color: #f2efef;
    --editor-toolbar-icon-color: #e8e8e8;

    --board-header-background-color: #f3f3f3;
    --nav-popover-board-color: #f3f3f3;
    --colaborators-tab-background-color: #e8e8e8;

    --select-item-hover-background-color: #f6efef;
    --selected-item-background-color: #d6d6d6;
    --auth-form-background-color: #ffffff;

    --auth-form-body-background-color: #f4f5f7;
    --social-login-button-border-color: #dfe1e6;
    --social-login-text-color: #5e6c84;
    --profile-card-action-background-hover-color: #f0f0f0;

    --skelton-base-color: #f4f5f7;
    --skelton-highlight-color: #e0e0e0;

    --skeleton-inner-background-color: #f4f5f7;
    --skeleton-header-wrapper-background-color: #ffffff;
    --skeleton-board-header-color: #5e6c84;

    --menu-item-icon-background-color: #e6f3ff;
    --menu-divider-color: #e0e0e0;
    --background-box-hover-overlay-color: #f0f0f0;
    --background-box-checkmark-color: #000000;
    --background-box-large-text-background-color: rgba(255, 255, 255, 0.8);
    --background-box-large-text-hover-color: #ffb400;

    --admin-role-bg-color: #d9dce1;
    --select-background-color: #cfd3d8;
    --select-border-color: #aaa;
    --inactive-hover-color: #aaa;
    --re-invite-hover-color: #a6a9ad;
    --invite-link-color: #303030;
    --invite-link-text-color: #303030;
    --link-action-hover-color: #0052cc;
    --sbm-tab-active-border-color: #0052cc;
    --sbm-tab-active-color: #0052cc;
    --member-username-color: #656a72;
    --member-status-color: #656a72;

    --notifications-icon-hover-color: #e5f0ff;
    --notifications-panel-background-color: #d9dce1;
    --notifications-panel-text-color: #3b3b3b;
    --notification-item-hover-border-color: #e7eaf0;
    --notification-item-unread-color: #0041a8;
    --comment-box-background-color: #ffc5c5;
    --notification-date-color: #777777;
    --prev-notification-link-background-color: #c5c6c8;

    --modal-overlay-background: rgba(0, 0, 0, 0.85);
    --zoobbe-select-border-color: var(--input-border-color);
    --zoobbe-select-hover-background-color: var(--select-item-hover-background-color);
    --zoobbe-select-option-selected-background-color: var(--selected-item-background-color);
    --scrollbar-thumb-background: #cccccc80;
    --scrollbar-track-background: transparent;
    --scrollbar-corner-background: var(--popover-background-color);

    --member-bg-color: #f4f9fb;
    --hover-bg-color: #aaa;
    --tab-active-color: #3a68c7;
    --tab-bottom-border-color: #e0e3e6;
    --inactive-text-color: #3c4346;
    --dropdown-bg-color: #f4f6f9;
    --dropdown-text-color: #6d6e6f;

    --error-message-color: #d90000;

    --checkbox-border-color: #d6e2f0;

    --other-h2-color: #bbb;
    --other-a-color: #444;
    --editor-text-color: #fff;
    --editor-placeholder-color: #555;
    --editor-highlight-color: #333;
    --toolbar-item-disabled-color: #b2b5b9;
    --tree-view-background: #f9fafa;
    --editor-heading-h1-color: rgb(240, 240, 240);
    --editor-heading-h2-color: rgb(150, 153, 157);
    --editor-quote-border-color: rgb(206, 208, 212);
    --editor-quote-text-color: rgb(150, 153, 157);
    --typehead-popover-background-color: #f0efef;
    --typehead-popover-item-selected-color: #f0efef;
    --editor-image-resizer-background-color: rgb(60, 132, 244);
    --image-caption-background-color: #2f2f2f;
    --editor-toolbar-icon-color: #3c4346;
    --editor-code-border-right-color: #333;

    --editor-token-comment-color: #708090;
    --editor-token-punctuation-color: var(--editor-placeholder-color);
    --editor-token-property-color: #cc3d3d;
    --editor-token-selector-color: #3c9d3c;
    --editor-token-operator-color: #db9f6c;
    --editor-token-attr-color: #2288e9;
    --editor-token-variable-color: #ff9a0a;
    --editor-token-function-color: #e65b91;
    --mention-background-color: #e4aaf1;
    --editor-toolbar-item-active-background-color: #b3c2d1;

    --secondary-outline-color: #ddd;
    --button-background-color: #db4e4e;
    --button-hover-background-color: #db4e4e91;

    --secondary-hover-background: #bbb;
    --member-initials-bg-color: #999;
    --add-member-bg-color: #555;

    --filter-text-color-muted: #3c4346;
    --filter-label-text-color: #444b56;
    --filter-link-color: var(--brand-color);
    --filter-icon-background: indigo;
    --filter-icon-person-background: #4a2d99;
    --filter-icon-overdue-background: #d10000;
    --filter-icon-due-nextday-background: #01c701;
    --filter-cards-count-text-color: #444;
    --activity-text-color: #303030;

    --change-visibility-hover-bg-color: #f2f4f7;
    --selected-option-bg-color: rgba(11, 102, 255, 0.2);
    --disabled-option-color: #d6d6d6;
    --option-details-text-color: rgba(95, 106, 117, 0.5);
    --hover-option-bg-color: #f2f4f7;
    --watching-checkmark-background-color: #a7afb8;
    --selected-email-bg-color: #ebdfdf;
    --prev-notification-background-color: #f3ecec;
    --prev-notification-background-hover-color: #e0e0e0;
    --editor-image-border-color: #fbf8f8;
    --profile-img-box-shadow: 0 0 20px rgb(244 234 234 / 80%);
    --text-selection-bg-color: rgba(200, 200, 200, 0.4);
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-white: #fff;
        --white: #fff;
        --black: #000;
        --primary-color: #181717;
        --popover-background-color: #181717;
        --create-form-background-color: #181717;
        --single-card-background-color: #202123;
        --single-card-side-action-bg-color: #2b2c2d;
        --single-card-side-action-font-weight: 500;
        --single-card-action-button-hover-color: #353b40;
        // --single-card-text-color: #9aa0a6;
        --single-card-text-color: #bec7d2;
        --brand-color: #0966ff;
        --secondary-brand-color: #38a801;
        --button-disabled-color: #0966ff9c;
        --hover-brand-color: #0966ff9c;
        --brand-hover-bg-color: rgba(11, 102, 255, 0.0509803922);
        --selected-option-text-color: #0b66ff;
        --select-option-hover-background: #0b66ff0d;
        --outline-color: #444;
        --secondary-outline-color: #444;
        --editor-outline-color: #44444475;
        --focus-outline-color: var(--brand-color);
        --input-border-color: #444;
        --element-border-radius: 2px;
        --editor-border-radius: 8px;
        --input-background-color: #181717;
        --popover-border-color: #242526;
        --popover-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        // --primary-text-color: #b6c2cf;
        // --primary-text-color: #9aa0a6;
        --primary-text-color: #bec7d2;
        --primary-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        --primary-text-link-color: #0079bf;
        --checklist-progressbar-bg-color: #353b40;
        --checklist-fill-progressbar-bg-color: #0052cc82;
        --button-background-color-1: #0052cc;
        --single-section-header-font-weight: 600;
        --popover-title-popover-font-weight: 500;

        --white-text-color-alternative: var(--primary-text-color);

        --input-selection-color: #2b2c2d;
        --input-selection-bgcolor: #6c757d;

        --label-text-color: #222;
        --label-font-weight: 600;
        --delete-button-bg-color: #f87168;
        --delete-button-text-color: #2b2c2d;
        --board-overlay-background-color: rgba(0, 0, 0, 0.2);
        --add-list-background-color: rgb(6, 6, 6);
        --add-list-background-hover-color: #08283b;
        --list-header-background-color: #06060796;
        --actionlist-background-color: #060606;
        --card-background-color: #171b1f;
        --board-option-button-background-color: #171b1f;
        --add-card-button-hover-background-color: rgb(23, 27, 32);
        --small-element-background-color: #171b20;
        --badge-background-color: #6c757d;
        --navbar-background-color: rgba(0, 0, 0, 0.2);
        --top-header-background-color: #1e1e1e;
        --profile-border-color: #3a3737;
        --profile-active-border-color: #233b5b;
        --notification-background-color: #3a3737;
        --filter-placeholder-background-color: #181717;
        --search-results-option-background-color: #060d11;
        --board-menu-item-background-color: #1a1a1a;
        --input-field-border-color: #333;
        --scrolbar-thumb-background-color: #333333;
        --filter-option-text-color: #cfd2d6;
        --checkbox-background-color: #222f39;
        --tooltip-border-color: #444;

        --workspace-background-color: #121217;
        --workspace-sidbar-item-hover-background-color: #d9dbdc;
        --workspace-icon-background-color: #242628;
        --board-item-background-color: #171b1e;
        --board-item-border-color: #171b1e;

        --editor-background-color: #101415;
        --editor-toolbar-background-color: #181818;
        --editor-toolbar-icon-color: #e8e8e8;
        --board-header-background-color: #323232;
        --nav-popover-board-color: #323232;
        --colaborators-tab-background-color: #3a3f47;

        --select-item-hover-background-color: #302d2d;
        --selected-item-background-color: #302d2d;
        --auth-form-background-color: #181717;
        --auth-form-body-background-color: #181717;

        --social-login-button-border-color: #434750;
        --social-login-text-color: #c0c7d1;
        --profile-card-action-background-hover-color: #272727;

        --skelton-base-color: #1f1f23;
        --skelton-highlight-color: #17171a36;

        --skeleton-inner-background-color: #141415;
        --skeleton-header-wrapper-background-color: #1a1a1b;
        --skeleton-board-header-color: #201e23;

        --menu-item-icon-background-color: #0966ff1a;
        --menu-divider-color: #2d2d2d;
        --background-box-hover-overlay-color: #ffffff24;
        --background-box-checkmark-color: #ffffff;
        --background-box-large-text-background-color: rgba(0, 0, 0, 0.5);
        --background-box-large-text-hover-color: #ffdd57;

        --admin-role-bg-color: #292b2f;
        --select-background-color: #40444b;
        --select-border-color: #555;
        --inactive-hover-color: #555;
        --re-invite-hover-color: #5f6467;
        --invite-link-color: #cfcfcf;
        --invite-link-text-color: #cfcfcf;
        --link-action-hover-color: #579dff;
        --sbm-tab-active-border-color: #579dff;
        --sbm-tab-active-color: #579dff;
        --member-username-color: #9aa0a680;
        --member-status-color: #9aa0a680;

        --notifications-icon-hover-color: #0966ff26;
        --notifications-panel-background-color: #181717;
        --notifications-panel-text-color: #c4bebe;
        --notification-item-hover-border-color: #232a32;
        --notification-item-unread-color: #1a73e8;
        --comment-box-background-color: #ff4444;
        --notification-date-color: #888888;
        --prev-notification-link-background-color: #414244;

        --modal-overlay-background: rgba(0, 0, 0, 0.85);
        --zoobbe-select-border-color: var(--input-border-color);
        --zoobbe-select-hover-background-color: var(--select-item-hover-background-color);
        --zoobbe-select-option-selected-background-color: var(--selected-item-background-color);
        --scrollbar-thumb-background: #333333a5;
        --scrollbar-track-background: transparent;
        --scrollbar-corner-background: var(--popover-background-color);

        --member-bg-color: #2c3338;
        --hover-bg-color: #555;
        --tab-active-color: #579dff;
        --tab-bottom-border-color: #3d4042;
        --inactive-text-color: #9aa0a680;
        --dropdown-bg-color: #40444b;
        --dropdown-text-color: #8c8d8e;

        --error-message-color: #ff4d4d; // New variable
        // --primary-color: #0056b3; // New variable

        --checkbox-border-color: #2e3646;

        --other-h2-color: #444;
        --other-a-color: #777;
        --editor-text-color: #000;
        --editor-placeholder-color: #9aa0a661;
        --editor-highlight-color: #999;
        --toolbar-item-disabled-color: #63666a73;
        --tree-view-background: #222;
        --editor-heading-h1-color: rgb(5, 5, 5);
        --editor-heading-h2-color: rgb(101, 103, 107);
        --editor-quote-border-color: rgb(206, 208, 212);
        --editor-quote-text-color: rgb(101, 103, 107);
        --typehead-popover-background-color: #302d2d;
        --typehead-popover-item-selected-color: #302d2d;
        --editor-image-resizer-background-color: rgb(60, 132, 244);
        --image-caption-background-color: #f4f4f4;
        --editor-toolbar-icon-color: #9aa0a685;
        --editor-code-border-right-color: #ccc;

        --editor-token-comment-color: slategray;
        --editor-token-punctuation-color: var(--editor-placeholder-color);
        /* Already defined */
        --editor-token-property-color: #905;
        --editor-token-selector-color: #690;
        --editor-token-operator-color: #9a6e3a;
        --editor-token-attr-color: #07a;
        --editor-token-variable-color: #e90;
        --editor-token-function-color: #dd4a68;
        --mention-background-color: #0748a8;
        --editor-toolbar-item-active-background-color: #181c28;

        --secondary-outline-color: #444; /* Outline color for select trigger */
        --button-background-color: #f87168; /* Background color for button */
        --button-hover-background-color: #f87268b7;

        --secondary-hover-background: #444; /* Hover background for close buttons */
        --member-initials-bg-color: #666; /* Background color for member initials */
        --add-member-bg-color: #ddd; /* Background color for add member button */

        --filter-text-color-muted: #a0a6b1; /* Used in .filter-container input */
        --filter-label-text-color: #6b717e; /* Used in .label */
        --filter-link-color: var(--brand-color); /* Used in .filter-options .show-more */
        --filter-icon-background: rebeccapurple; /* General icon background */
        --filter-icon-person-background: #663399; /* Specific to .person */
        --filter-icon-overdue-background: #ff0000; /* Specific to .overdue */
        --filter-icon-due-nextday-background: #029e02; /* Specific to .due-nextday */
        --filter-cards-count-text-color: #6c757d;

        --activity-text-color: #a1a1a1;

        --change-visibility-hover-bg-color: #242628; /* Hover background color */
        --selected-option-bg-color: rgba(11, 102, 255, 0.0509803922); /* Selected option background color */
        --disabled-option-color: #9e9e9e; /* Disabled option color */
        --option-details-text-color: rgba(159, 173, 188, 0.7803921569); /* Option details text color */
        --hover-option-bg-color: #242628;
        --watching-checkmark-background-color: #353b40;
        --selected-email-bg-color: #2c3338;
        --prev-notification-background-color: #3a3838;
        --editor-image-border-color: #444;
        --profile-img-box-shadow: 0 0 20px rgba(31, 28, 28, 0.8);
        --text-selection-bg-color: rgba(150, 150, 150, 0.4);
    }
}
@media (prefers-color-scheme: light) {
    :root[data-theme="light"] {
        --color-white: #000; /* Opposite of white for dark text */
        --primary-color: #f9f9f9; /* Light background */
        --popover-background-color: #f9f9f9;
        --create-form-background-color: #ffffff;
        --single-card-background-color: #ffffff; /* Bright card background */
        --single-card-side-action-bg-color: #f3ecec; /* Softer background */
        --single-card-side-action-font-weight: 500;
        --single-card-action-button-hover-color: #e0e0e0;
        --single-card-text-color: #3a4b65; /* Dark text for light mode */
        --brand-color: #0052cc; /* Complementary brand color for light mode */
        --secondary-brand-color: #38a801;
        --button-disabled-color: #0052cc9c;
        --hover-brand-color: #0052cc9c;
        --brand-hover-bg-color: rgba(0, 82, 204, 0.05);
        --selected-option-text-color: #0b66ff;
        --select-option-hover-background: #0b66ff0d;
        --outline-color: #ddd; /* Softer outline color */
        --editor-outline-color: #ddd;
        --secondary-outline-color: #ddd; /* Adjusted purple shade */
        --focus-outline-color: var(--brand-color);
        --input-border-color: #ddd;
        --element-border-radius: 2px;
        --editor-border-radius: 8px;
        --input-background-color: #ffffff;
        --popover-border-color: #e0e0e0;
        --popover-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Lighter shadow */
        --primary-text-color: #3a4b65; /* Darker text for readability */
        --primary-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        --primary-text-link-color: #0079bf;
        --checklist-progressbar-bg-color: #d7e1ea;
        --checklist-fill-progressbar-bg-color: #0052cc82;
        --button-background-color-1: #0073e6; /* Slightly brighter button */
        --single-section-header-font-weight: 600;
        --popover-title-popover-font-weight: 500;

        --white-text-color-alternative: var(--primary-text-color);

        --input-selection-color: #2b2c2d;
        --input-selection-bgcolor: #6c757d;

        --label-text-color: #555; /* Medium contrast text */
        --label-font-weight: 600;
        --delete-button-bg-color: #ff6f61; /* Complementary delete button color */
        --delete-button-text-color: #ffffff;

        --board-overlay-background-color: rgba(0, 0, 0, 0.2);
        --add-list-background-color: rgb(245, 245, 245);
        --add-list-background-hover-color: #b3d7f0;
        --list-header-background-color: #ffffff;
        --actionlist-background-color: #ededed;
        --card-background-color: #ffffff;
        --board-option-button-background-color: #ffffff;
        --add-card-button-hover-background-color: #e0dbdb;
        --small-element-background-color: #e8edf3;
        --badge-background-color: #d6d9dc;
        --navbar-background-color: rgba(255, 255, 255, 0.2);
        --top-header-background-color: #ffffff;

        --profile-border-color: #c8c8c8;
        --profile-active-border-color: #adc4e6;
        --notification-background-color: #c8c8c8;
        --filter-placeholder-background-color: #f9f9f9;
        --search-results-option-background-color: #e0e4e8;
        --board-menu-item-background-color: #f5f5f5;
        --input-field-border-color: #c6c6c6;
        --scrolbar-thumb-background-color: #e0e0e0;
        --filter-option-text-color: #3a4b65;
        --checkbox-background-color: #e0e5e8;
        --tooltip-border-color: #d1d1d1;

        --workspace-background-color: #ffffff;
        --workspace-sidbar-item-hover-background-color: #242728;
        --workspace-icon-background-color: #f1f3f5;
        --board-item-background-color: #f7f7f7;
        --board-item-border-color: #e4e7eb;

        --editor-background-color: #fcfcfc;
        --editor-toolbar-background-color: #f2efef;
        --editor-toolbar-icon-color: #e8e8e8;

        --board-header-background-color: #f3f3f3;
        --nav-popover-board-color: #f3f3f3;
        --colaborators-tab-background-color: #e8e8e8;

        --select-item-hover-background-color: #f6efef;
        --selected-item-background-color: #d6d6d6;
        --auth-form-background-color: #ffffff;

        --auth-form-body-background-color: #f4f5f7;
        --social-login-button-border-color: #dfe1e6;
        --social-login-text-color: #5e6c84;
        --profile-card-action-background-hover-color: #f0f0f0;

        --skelton-base-color: #f4f5f7;
        --skelton-highlight-color: #e0e0e0;

        --skeleton-inner-background-color: #f4f5f7;
        --skeleton-header-wrapper-background-color: #ffffff;
        --skeleton-board-header-color: #5e6c84;

        --menu-item-icon-background-color: #e6f3ff;
        --menu-divider-color: #e0e0e0;
        --background-box-hover-overlay-color: #f0f0f0;
        --background-box-checkmark-color: #000000;
        --background-box-large-text-background-color: rgba(255, 255, 255, 0.8);
        --background-box-large-text-hover-color: #ffb400;

        --admin-role-bg-color: #d9dce1;
        --select-background-color: #cfd3d8;
        --select-border-color: #aaa;
        --inactive-hover-color: #aaa;
        --re-invite-hover-color: #a6a9ad;
        --invite-link-color: #303030;
        --invite-link-text-color: #303030;
        --link-action-hover-color: #0052cc;
        --sbm-tab-active-border-color: #0052cc;
        --sbm-tab-active-color: #0052cc;
        --member-username-color: #656a72;
        --member-status-color: #656a72;

        --notifications-icon-hover-color: #e5f0ff;
        --notifications-panel-background-color: #d9dce1;
        --notifications-panel-text-color: #3b3b3b;
        --notification-item-hover-border-color: #e7eaf0;
        --notification-item-unread-color: #0041a8;
        --comment-box-background-color: #ffc5c5;
        --notification-date-color: #777777;
        --prev-notification-link-background-color: #c5c6c8;

        --modal-overlay-background: rgba(0, 0, 0, 0.85);
        --zoobbe-select-border-color: var(--input-border-color);
        --zoobbe-select-hover-background-color: var(--select-item-hover-background-color);
        --zoobbe-select-option-selected-background-color: var(--selected-item-background-color);
        --scrollbar-thumb-background: #cccccc80;
        --scrollbar-track-background: transparent;
        --scrollbar-corner-background: var(--popover-background-color);

        --member-bg-color: #f4f9fb;
        --hover-bg-color: #aaa;
        --tab-active-color: #3a68c7;
        --tab-bottom-border-color: #e0e3e6;
        --inactive-text-color: #3c4346;
        --dropdown-bg-color: #f4f6f9;
        --dropdown-text-color: #6d6e6f;

        --error-message-color: #d90000;

        --checkbox-border-color: #d6e2f0;

        --other-h2-color: #bbb;
        --other-a-color: #444;
        --editor-text-color: #fff;
        --editor-placeholder-color: #555;
        --editor-highlight-color: #333;
        --toolbar-item-disabled-color: #b2b5b9;
        --tree-view-background: #f9fafa;
        --editor-heading-h1-color: rgb(240, 240, 240);
        --editor-heading-h2-color: rgb(150, 153, 157);
        --editor-quote-border-color: rgb(206, 208, 212);
        --editor-quote-text-color: rgb(150, 153, 157);
        --typehead-popover-background-color: #f0efef;
        --typehead-popover-item-selected-color: #f0efef;
        --editor-image-resizer-background-color: rgb(60, 132, 244);
        --image-caption-background-color: #2f2f2f;
        --editor-toolbar-icon-color: #3c4346;
        --editor-code-border-right-color: #333;

        --editor-token-comment-color: #708090;
        --editor-token-punctuation-color: var(--editor-placeholder-color);
        --editor-token-property-color: #cc3d3d;
        --editor-token-selector-color: #3c9d3c;
        --editor-token-operator-color: #db9f6c;
        --editor-token-attr-color: #2288e9;
        --editor-token-variable-color: #ff9a0a;
        --editor-token-function-color: #e65b91;
        --mention-background-color: #e4aaf1;
        --editor-toolbar-item-active-background-color: #b3c2d1;

        --secondary-outline-color: #ddd;
        --button-background-color: #db4e4e;
        --button-hover-background-color: #db4e4e91;

        --secondary-hover-background: #bbb;
        --member-initials-bg-color: #999;
        --add-member-bg-color: #555;

        --filter-text-color-muted: #3c4346;
        --filter-label-text-color: #444b56;
        --filter-link-color: var(--brand-color);
        --filter-icon-background: indigo;
        --filter-icon-person-background: #4a2d99;
        --filter-icon-overdue-background: #d10000;
        --filter-icon-due-nextday-background: #01c701;
        --filter-cards-count-text-color: #444;
        --activity-text-color: #303030;

        --change-visibility-hover-bg-color: #f2f4f7;
        --selected-option-bg-color: rgba(11, 102, 255, 0.2);
        --disabled-option-color: #d6d6d6;
        --option-details-text-color: rgba(95, 106, 117, 0.5);
        --hover-option-bg-color: #f2f4f7;
        --watching-checkmark-background-color: #a7afb8;
        --selected-email-bg-color: #ebdfdf;
        --prev-notification-background-color: #f3ecec;
        --prev-notification-background-hover-color: #e0e0e0;
        --editor-image-border-color: #fbf8f8;
        --profile-img-box-shadow: 0 0 20px rgb(244 234 234 / 80%);
        --text-selection-bg-color: rgba(200, 200, 200, 0.4);
    }
}

/* Theme Variants */
:root[data-theme-color="blue"] {
    --brand-color: #0966ff;
    --button-disabled-color: #0966ff9c;
    --hover-brand-color: #0966ff9c;
    --brand-hover-bg-color: rgba(9, 102, 255, 0.05);
    --selected-option-text-color: #0b66ff;
    --select-option-hover-background: #0b66ff0d;
    --menu-item-icon-background-color: #0966ff1a;
    --notifications-icon-hover-color: #0966ff26;
    --notification-item-unread-color: #1a73e8;
    --link-action-hover-color: #579dff;
    --sbm-tab-active-border-color: #579dff;
    --sbm-tab-active-color: #579dff;
    --tab-active-color: #579dff;
    --primary-text-link-color: #0079bf;
    --checklist-fill-progressbar-bg-color: #0052cc82;
    --button-background-color-1: #0052cc;
}

:root[data-theme-color="purple"] {
    --brand-color: #7f77f1;
    --button-disabled-color: #7f77f19c;
    --hover-brand-color: #7f77f19c;
    --brand-hover-bg-color: rgba(127, 119, 241, 0.05);
    --selected-option-text-color: #7266ff;
    --select-option-hover-background: #7266ff0d;
    --menu-item-icon-background-color: #7f77f11a;
    --notifications-icon-hover-color: #7f77f126;
    --notification-item-unread-color: #6358e8;
    --link-action-hover-color: #9487ff;
    --sbm-tab-active-border-color: #9487ff;
    --sbm-tab-active-color: #9487ff;
    --tab-active-color: #9487ff;
    --primary-text-link-color: #6358e8;
    --checklist-fill-progressbar-bg-color: #6358e882;
    --button-background-color-1: #6358e8;
}

:root[data-theme-color="sky-blue"] {
    --brand-color: #1090e0;
    --button-disabled-color: #1090e09c;
    --hover-brand-color: #1090e09c;
    --brand-hover-bg-color: rgba(16, 144, 224, 0.05);
    --selected-option-text-color: #0d88d8;
    --select-option-hover-background: #0d88d80d;
    --menu-item-icon-background-color: #1090e01a;
    --notifications-icon-hover-color: #1090e026;
    --notification-item-unread-color: #1078c8;
    --link-action-hover-color: #3da5f5;
    --sbm-tab-active-border-color: #3da5f5;
    --sbm-tab-active-color: #3da5f5;
    --tab-active-color: #3da5f5;
    --primary-text-link-color: #1078c8;
    --checklist-fill-progressbar-bg-color: #1078c882;
    --button-background-color-1: #1078c8;
}

:root[data-theme-color="pink"] {
    --brand-color: #ee5e99;
    --button-disabled-color: #ee5e999c;
    --hover-brand-color: #ee5e999c;
    --brand-hover-bg-color: rgba(238, 94, 153, 0.05);
    --selected-option-text-color: #e44b8a;
    --select-option-hover-background: #e44b8a0d;
    --menu-item-icon-background-color: #ee5e991a;
    --notifications-icon-hover-color: #ee5e9926;
    --notification-item-unread-color: #dc4581;
    --link-action-hover-color: #f48ab8;
    --sbm-tab-active-border-color: #f48ab8;
    --sbm-tab-active-color: #f48ab8;
    --tab-active-color: #f48ab8;
    --primary-text-link-color: #dc4581;
    --checklist-fill-progressbar-bg-color: #dc458182;
    --button-background-color-1: #dc4581;
}

:root[data-theme-color="violet"] {
    --brand-color: #b660e0;
    --button-disabled-color: #b660e09c;
    --hover-brand-color: #b660e09c;
    --brand-hover-bg-color: rgba(182, 96, 224, 0.05);
    --selected-option-text-color: #a452d8;
    --select-option-hover-background: #a452d80d;
    --menu-item-icon-background-color: #b660e01a;
    --notifications-icon-hover-color: #b660e026;
    --notification-item-unread-color: #953cc8;
    --link-action-hover-color: #cf8ff5;
    --sbm-tab-active-border-color: #cf8ff5;
    --sbm-tab-active-color: #cf8ff5;
    --tab-active-color: #cf8ff5;
    --checklist-fill-progressbar-bg-color: #953cc882;
    --button-background-color-1: #953cc8;
}

:root[data-theme-color="orange"] {
    --brand-color: #e16b16;
    --button-disabled-color: #e16b169c;
    --hover-brand-color: #e16b169c;
    --brand-hover-bg-color: rgba(225, 107, 22, 0.05);
    --selected-option-text-color: #d25f12;
    --select-option-hover-background: #d25f120d;
    --menu-item-icon-background-color: #e16b161a;
    --notifications-icon-hover-color: #e16b1626;
    --notification-item-unread-color: #b3530c;
    --link-action-hover-color: #f59b54;
    --sbm-tab-active-border-color: #f59b54;
    --sbm-tab-active-color: #f59b54;
    --tab-active-color: #f59b54;
    --checklist-fill-progressbar-bg-color: #b3530c82;
    --button-background-color-1: #b3530c;
}

:root[data-theme-color="teal"] {
    --brand-color: #0f9d9f;
    --button-disabled-color: #0f9d9f9c;
    --hover-brand-color: #0f9d9f9c;
    --brand-hover-bg-color: rgba(15, 157, 159, 0.05);
    --selected-option-text-color: #0e8d8f;
    --select-option-hover-background: #0e8d8f0d;
    --menu-item-icon-background-color: #0f9d9f1a;
    --notifications-icon-hover-color: #0f9d9f26;
    --notification-item-unread-color: #0b7476;
    --link-action-hover-color: #54c7c8;
    --sbm-tab-active-border-color: #54c7c8;
    --sbm-tab-active-color: #54c7c8;
    --tab-active-color: #54c7c8;
    --checklist-fill-progressbar-bg-color: #0b747682;
    --button-background-color-1: #0b7476;
}

:root[data-theme-color="brown"] {
    --brand-color: #aa8d80;
    --button-disabled-color: #aa8d809c;
    --hover-brand-color: #aa8d809c;
    --brand-hover-bg-color: rgba(170, 141, 128, 0.05);
    --selected-option-text-color: #997b6e;
    --select-option-hover-background: #997b6e0d;
    --menu-item-icon-background-color: #aa8d801a;
    --notifications-icon-hover-color: #aa8d8026;
    --notification-item-unread-color: #8a6b5c;
    --link-action-hover-color: #c7a99e;
    --sbm-tab-active-border-color: #c7a99e;
    --sbm-tab-active-color: #c7a99e;
    --tab-active-color: #c7a99e;
    --checklist-fill-progressbar-bg-color: #8a6b5c82;
    --button-background-color-1: #8a6b5c;
}

:root[data-theme-color="green"] {
    --brand-color: #3cb88b;
    --button-disabled-color: #3cb88b9c;
    --hover-brand-color: #3cb88b9c;
    --brand-hover-bg-color: rgba(60, 184, 139, 0.05);
    --selected-option-text-color: #34a57e;
    --select-option-hover-background: #34a57e0d;
    --menu-item-icon-background-color: #3cb88b1a;
    --notifications-icon-hover-color: #3cb88b26;
    --notification-item-unread-color: #298f6c;
    --link-action-hover-color: #64d4a6;
    --sbm-tab-active-border-color: #64d4a6;
    --sbm-tab-active-color: #64d4a6;
    --tab-active-color: #64d4a6;
    --primary-text-link-color: #298f6c;
    --checklist-fill-progressbar-bg-color: #298f6c82;
    --button-background-color-1: #298f6c;
}

input:-internal-autofill-selected {
    background-color: var(--input-background-color) !important;
}

input:autofill {
    background-color: transparent !important; /* Ensures autofill doesn't revert to white */
}

input:autofill:hover,
input:autofill:focus {
    background-color: transparent !important; /* Optional: Adjust when focused or hovered */
}

input:-webkit-autofill {
    background-color: transparent !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    background-color: transparent !important; /* Keep transparent on hover/focus */
}

input::placeholder {
    background-color: transparent; /* Ensure the background doesn't affect it */
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.disabled {
    user-select: none !important;
    background-color: var(--button-disabled-color) !important;
    &:hover {
        cursor: inherit !important;
    }
}

span.material-symbols-outlined {
    user-select: none;
}

.zoobbe-feedback-container {
    position: fixed;
    z-index: 3;
    right: 20px;
    bottom: 20px;

    button.feedback-button {
        display: flex;
        align-items: center;
        gap: 5px;
        background: var(--brand-color);
        border: 0;
        color: #fff;
        height: 35px;
        border-radius: 30px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        justify-content: center;
        box-shadow: none;

        &:hover {
            background: var(--hover-brand-color);
        }

        span {
            font-size: 30px;
        }
    }
}

div#feedBack {
    position: fixed !important;

    p.feedback-page-link {
        a {
            color: var(--brand-color);
        }
    }
}

.full-deactivated {
    pointer-events: none;
    opacity: 0.5;
}

.verify-container {
    display: flex;
    justify-content: center;
    margin-top: 20%;
    .verifying-text {
        align-content: center;
        text-align: center;
        color: var(--primary-text-color);
        background: var(--single-card-side-action-bg-color);
        padding: 0px 12px;
        border-radius: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

svg.zoobbe-flag-low {
    fill: rgb(96, 96, 96);
}
svg.zoobbe-flag-high {
    fill: rgb(255, 197, 61);
}
svg.zoobbe-flag-normal {
    fill: #3e63dd;
}
svg.zoobbe-flag-urgent {
    fill: rgb(255, 133, 137);
}

.online-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    right: -4px;
    border: 2px solid var(--card-background-color);
}

.online-indicator.online {
    background-color: #54d454; /* Green for online */
}

.online-indicator.offline {
    background-color: #d45454; /* Red for offline */
}

.board-members {
    .online-indicator {
        border-radius: 50%;
        position: absolute;
        bottom: 3px;
        right: 0px;
        border: none;
    }
}

.profile-card__content {
    .online-indicator {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        bottom: 5px;
        right: 15px;
        border: 2px solid var(--card-background-color);
    }
}

.image-placeholder-container {
    position: relative;
    display: inline-flex;
}
