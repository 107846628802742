.comment-section {
    margin-left: -45px;
    .comment {
        display: flex;
        align-items: flex-start;
        padding: 10px 0 0 10px;
        border-radius: 8px;
        gap: 10px;

        .image-placeholder {
            margin-right: 10px;
        }

        .card-comment-content {
            background-color: var(--single-card-side-action-bg-color);
            padding: 8px 12px;
            border-radius: var(--editor-border-radius);
            margin-top: 5px;
        }
        .write-comment-field {
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                background-color: var(--single-card-action-button-hover-color);
            }
        }

        &__profile-img {
            border-radius: 50%;
            margin-right: 10px;
            width: 35px;
            height: 35px;
            background-color: var(--color-white);
            box-shadow: var(--profile-img-box-shadow);
        }

        .comment-editor-field {
            margin-top: -4px;
        }
        &__content {
            flex: 1;
            max-width: 100%;

            .comment__header {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 15px;

                span {
                    font-size: 12px;
                    color: #416068;
                    font-family: system-ui;
                    font-weight: 700;
                }
            }

            .comment__loading {
                font-size: 12px;
                margin-left: 5px;
                opacity: 0.6;
            }

            .comment__link {
                color: var(--brand-color);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .comment__actions {
                margin-top: 5px;

                .comment__action-link {
                    font-size: 12px;
                    color: #a1a1a1;
                    text-decoration: none;
                    margin-right: 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    // .comment-input-field {
    //     align-items: center;
    // }
}

.comment-section .comment__actions {
    display: block;
}

.comment-section .quill-container {
    // box-shadow: inset 0 0 0 2px #388bff;
    border-radius: var(--editor-border-radius);
    // background-color: var(--white-text-color-alternative);
}

.comment-section .ql-toolbar.ql-snow {
    border: none;
    // border-bottom: 1px solid #c5c6f7 !important;
}
.comment-section .quill-container .ql-container,
.comment-section .quill-container .ql-container .ql-editor {
    min-height: 80px !important;
    margin: 0;
}

.card-comment-content p {
    padding: 0;
    margin-top: 0;

    &:last-child {
        margin: 0;
    }
}
