/* Container */
.login-container,
.reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--auth-form-body-background-color);
    min-height: 100vh;
}

/* Login Box */
.login-box,
.reset-box {
    width: 300px;
    padding: 40px;
    // background-color: var(--auth-form-background-color);
    border-radius: 8px;
    // text-align: center;
    margin: 40px 0;

    .login-logo,
    .reset-logo {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
        margin-top: 0px;
        color: var(--brand-color);
    }

    .login-heading,
    .reset-heading {
        font-size: 15px;
        margin-bottom: 20px;
        color: var(--social-login-text-color);
        margin-top: 0;
        font-weight: 400;
    }

    .login-form,
    .signup-form,
    .reset-form {
        .login-input,
        .reset-input {
            width: calc(100% - 20px);
            padding: 0 10px;
            margin-bottom: 20px;
            border-radius: var(--element-border-radius);
            font-size: 14px;
            height: 35px;
            border: none;
            outline: 1px solid var(--input-border-color);
            background-color: var(--input-background-color);
            color: var(--primary-text-color);

            &:focus {
                width: calc(100% - 20px);
                padding: 0 10px;
                margin-bottom: 20px;
                outline: 2px solid var(--brand-color);
                border-radius: var(--element-border-radius);
                font-size: 14px;
                height: 35px;
                box-shadow: none;
                border: none;
            }
        }

        .login-button,
        .reset-button {
            width: 100%;
            padding: 0 10px;
            background-color: var(--brand-color);
            color: var(--white);
            border: none;
            border-radius: var(--element-border-radius);
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 20px;
            height: 35px;

            &:hover {
                background-color: var(--hover-brand-color);
            }
        }
    }

    .login-or {
        margin-bottom: 20px;
        color: var(--social-login-text-color);
        margin-top: 0;
    }

    .login-social-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .social-button {
            width: 100%;
            padding: 10px;
            border: 1px solid var(--social-login-button-border-color);
            border-radius: var(--element-border-radius);
            font-size: 14px;
            cursor: pointer;

            &.google,
            &.microsoft,
            &.apple,
            &.slack {
                background-color: var(--auth-form-body-background-color);
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
                font-weight: 600;
                color: var(--color-white);
            }
        }
    }

    .login-links {
        margin: 20px 0;
        color: var(--social-login-text-color);
        font-size: 15px;

        .login-link {
            color: var(--primary-text-link-color);
            text-decoration: none;
            font-size: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .login-footer {
        margin-top: 20px;
        font-size: 12px;
        color: var(--social-login-text-color);

        .login-atlassian {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .login-footer-text {
            margin-bottom: 10px;
            margin-bottom: 10px;
            color: var(--primary-text-color);
            font-size: 15px;
            font-weight: 400;
        }

        .login-privacy {
            margin-bottom: 10px;

            .login-privacy-link {
                color: var(--brand-color);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .login-footer-note {
            font-size: 10px;
            line-height: 1.6;
            a {
                color: var(--primary-text-link-color);
            }
        }
    }
}

p.legal-message {
    font-size: 12px;
    text-align: left;
    margin: 0 0 15px 0;
    padding: 0;
    color: var(--primary-text-color);
    line-height: 1.6;

    a {
        color: var(--primary-text-link-color);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.success-message {
    color: green;
    margin-top: 0px;
    font-weight: 400;
    font-family: system-ui;
}

.error-message {
    color: red !important;
    margin-top: 10px;
    font-weight: 400;
    font-family: system-ui;
}

span.label {
    display: inline-block;
    text-align: left !important;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--color-white);
}

.error-message {
    color: red !important;
    font-size: 12px;
    text-align: left;
    margin: 0 0 15px 0;
    padding: 0;
}

.reset-links {
    color: var(--primary-text-color);

    a.reset-link {
        color: var(--brand-color);
    }
}

.react-cloudflare-turnstile {
    margin-bottom: 15px;
}