.labels-container {
    width: calc(100% - 30px);
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: system-ui;
    width: 280px;

    .labels-header {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        margin-bottom: 20px;

        h3 {
            margin: 0;
            font-size: 14px;
            text-align: center;
            font-weight: var(--popover-title-popover-font-weight);
        }

        .close-button {
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;
        }
    }

    .search-input {
        width: 100%;
        padding: 0 8px;
        margin-bottom: 15px;
        border-radius: var(--element-border-radius);
        // outline: 1px solid var(--outline-color);
        outline: 1px solid var(--secondary-outline-color);
        background-color: var(--popover-background-color);
        color: var(--single-card-text-color);
        height: 35px;
        border: none;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        &:focus {
            outline: 2px solid var(--focus-outline-color);
        }
        font-weight: 600;
    }

    .labels-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .label-item {
            display: flex;
            align-items: center;
            gap: 10px;

            .label-color {
                width: 100%;
                height: 30px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.75;
                color: var(--label-text-color);
                font-weight: var(--label-font-weight);
                cursor: pointer;
                font-size: 14px;
                font-family: system-ui;

                &:hover {
                    opacity: 0.6 !important;
                }
            }

            .edit-button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 14px;
                width: 30px;
                height: 30px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: var(--single-card-side-action-bg-color);
                }

                span {
                    color: var(--single-card-text-color);
                    font-size: 18px;
                }
            }
        }
    }

    .create-label-button,
    .colorblind-mode-button {
        width: 100%;
        margin-top: 15px;
        border: none;
        border-radius: var(--element-border-radius);
        cursor: pointer;
        font-size: 14px;
        background-color: var(--single-card-side-action-bg-color);
        height: 35px;
        font-family: system-ui;
        color: var(--primary-text-color);
        font-weight: 600;
        &:hover {
            background-color: var(--single-card-action-button-hover-color);
        }
    }
}

.edit-label-modal {
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        max-width: 170px;
        margin-top: -10px;

        h3 {
            font-size: 14px;
            color: var(--single-card-text-color);
            font-weight: 600;
            font-family: system-ui;
        }

        .back-button,
        .close-button {
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
        }

        .back-button span {
            font-size: 18px;
            color: var(--single-card-text-color);
        }
    }

    .label-preview {
        width: calc(100% - 20px);
        padding: 0 10px;
        background: #ddd;
        border-radius: var(--element-border-radius);
        text-align: center;
        margin-bottom: 15px;
        font-size: 16px;
        height: 35px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: var(--label-text-color);
        font-weight: var(--label-font-weight);
        opacity: 0.75;
    }

    label {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .title-input {
        width: calc(100% - 20px);
        padding: 0 10px;
        background-color: var(--input-background-color);
        outline: 1px solid var(--secondary-outline-color);
        border-radius: var(--element-border-radius);
        color: var(--white-text-color-alternative);
        font-size: 14px;
        border: none;
        height: 35px;
        margin-bottom: 15px;
        color: var(--single-card-text-color);
        font-weight: 600;

        &:focus {
            outline: 2px solid var(--focus-outline-color);
        }
    }

    .color-picker {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 4px;
        margin-bottom: 8px;

        .color-box {
            height: 34px;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            border: 2px solid transparent;
            opacity: 0.75;

            &.selected {
                border: 2px solid transparent;
            }
        }
    }

    .remove-color-button {
        background: none;
        border: none;
        color: var(--single-card-text-color);
        cursor: pointer;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
        padding: 5px;
        text-align: center;
        justify-content: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .save-button,
        .delete-button,
        .create-button {
            width: 100px;
            padding: 10px;
            border: none;
            border-radius: var(--element-border-radius);
            cursor: pointer;
            font-size: 14px;
            font-family: system-ui;
            font-weight: 600;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .save-button {
            background-color: var(--brand-color);
            color: var(--white);

            &:hover {
                background-color: var(--hover-brand-color);
            }
        }
        .create-button {
            color: var(--single-card-text-color);
            background-color: var(--single-card-side-action-bg-color);
            &:hover {
                background-color: var(--single-card-action-button-hover-color);
            }
        }
        .delete-button {
            background: var(--delete-button-bg-color);
            color: var(--delete-button-text-color);
            &:hover {
                background-color: darken($color: #f87168, $amount: 10%);
            }
        }
    }
}
