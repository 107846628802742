/* Header Styles */
/* Home Page Styles */

.hero__container {
    max-width: calc(100% - 80px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 40px;

    .deploy-version {
        margin-bottom: 1rem;
        font-weight: 700;
        display: block;
    }
}

.hero_logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 20px;

    svg {
        width: 200px !important;
        height: auto;
    }

    p {
        margin: 0;
        display: flex;
        align-items: center;
    }

    .logo-beta {
        color: #ff4500;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 0.3rem;
        margin-top: -30px;
    }
}

.hero__actions {
    display: flex;
    gap: 1rem;
    text-align: left;

    .hero__login {
        background: transparent;
        border: 2px solid var(--brand-color);
        color: var(--brand-color);
        padding: 0.5rem 1rem;
        border-radius: 5px;
        text-decoration: none;
        transition: all 0.3s ease;
        font-weight: 500;

        &:hover {
            background: var(--brand-color);
            color: var(--white);
        }
    }

    .hero__cta {
        background: #38a801;
        color: #000;
        padding: 0.5rem 1.5rem;
        border-radius: 5px;
        text-decoration: none;
        font-weight: 500;
        transition: background 0.3s ease;
        height: 44px !important;

        &:hover {
            background: darken(#38a801, 10%);
        }
    }
}

/* Hero Section */
.hero {
    /* background: linear-gradient(135deg, #004fd2, rgba(86, 249, 0, 0.43)); */
    color: var(--white);
    text-align: center;
    height: 100vh;
    align-content: center;
    // width: 1200px;
    margin: auto;

    background: url(https://cloud.zoobbe.com/backgrounds/2560x1707/d040753afebe00f3123a5522318155a6ec70a885ac23ecfadd82e5564cd91837/photo-1735181056575-1f05648efbad.webp);
    // background: url(https://images.unsplash.com/photo-1733959136134-4aff9c694857);
    color: var(--white);
    text-align: center;
    height: 100vh;
    align-content: center;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: 0;
    background-position: 50% center;

    &__title {
        font-size: 3rem;
        margin-bottom: 0;
        color: var(--white);
        margin-top: 0;
        font-weight: 500;
    }

    &__subtitle {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        margin-top: 0;
        color: var(--white);
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;

        input {
            padding: 0.7rem;
            border: none;
            border-radius: 4px;
            font-size: 1rem;
            width: 250px;
        }

        button {
            padding: 0.7rem 1.5rem;
            border: none;
            background: #61bd4f;
            color: var(--white);
            border-radius: 4px;
            cursor: pointer;
            font-size: 1rem;
            transition: background 0.3s;

            &:hover {
                background: #519839;
            }
        }
    }

    &__link {
        color: var(--white);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #ddd;
        }
    }

    &__container {
        width: 800px;
        text-align: left;
        margin: auto;
        z-index: 1;
    }

    .hero__actions {
        display: flex;
        gap: 1rem;

        a {
            padding: 0 1rem;
            cursor: pointer;
            border-radius: 2px;
            font-size: 1rem;
            transition: background 0.3s;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            text-decoration: none;
        }

        &__login {
            background: transparent;
            color: var(--white);
            height: 44px;
            font-weight: 500;

            &:hover {
                background: #005a8c;
            }
        }

        &__cta {
            background: #61bd4f;
            color: var(--white);
            height: 44px !important;
            font-weight: 500;

            &:hover {
                background: darken($color: #519839, $amount: 10);
            }
        }
    }
}

section.hero:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    /* background: linear-gradient(-135deg, var(--brand-color)7a, rgba(86, 249, 0, 0.43)); */
    width: 100%;
    background-color: #000000ab;
    backdrop-filter: blur(2px);
}

@media screen and (max-width: 768px) {
    .hero__title {
        font-family: inherit;
        font-size: 2em;
        line-height: 1.4;
        font-weight: 500;
    }
}
